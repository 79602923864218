.modal_body {
  padding: 2rem;
  position: relative;
}

.button_close {
  top: 20px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  font-size: calc(1rem + 1vw);
}

.modal_title {
  margin: 1rem 0;
  text-align: center;
  font-size: calc(1rem + 1vw);
  font-family: var(--FONT_BOLD);
}

.container_image {
  margin-bottom: 1rem;
}

.body {
  text-align: center;
}

.text_blue {
  margin: 0;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.text_info {
  margin: 1rem 0;
  font-size: .9rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.important {
  font-family: var(--FONT_BOLD);
}

.container_button {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .container_button {
    width: 75%;
  } 
}

@media (max-width: 500px) {
  .container_button {
    width: 85%;
  } 
}

@media (max-width: 350px) {
  .container_button {
    width: 95%;
  } 
}