.back {
  left: 2%;
  display: flex;
  margin: 1rem 0;
  cursor: pointer;
  padding: 7.5px 0;
  position: absolute;
  align-items: center;
  color: var(--MEDIUM_BLUE);
}

.Logo {
  height: 55px;
  top: 30px;
  right: 30px;
  position: absolute;
}

.textBack {
  margin: 0;
  font-family: var(--FONT_BOLD);
}

@media (max-width: 768px) {
  .textBack {
    display: none;
  }
  .logoContainer {
    display: flex;
    justify-content: center;
  }
  .Logo {
    position: relative;
    margin-top: 12px;
    top: 0px;
    right: 0px;
  }
}
