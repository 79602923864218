.container {
  min-height: 4.5rem;
  margin-bottom: -1rem;
}
.input_date { 
  margin: 0;
  width: 100%;
  color: #999; 
  outline: none;
  display: flex;
  min-height: 3rem;
  text-align: center;
  border-radius: 10vh;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  font-family: var(--FONT_LIGHT);
  border: 2px solid rgb(184, 184, 184);
}
.input_date:hover {
  cursor: pointer;
}
.input_date :global .react-date-picker__wrapper {
  border: none;
}
.input_date :global .react-date-picker__inputGroup__input:invalid {
  outline: none !important;
  background: none;
}
.input_date :global .react-date-picker__inputGroup__day,
.input_date :global .react-date-picker__inputGroup__month,
.input_date :global .react-date-picker__inputGroup__year {
  outline: none !important;
}
.error {
  font-size: 0.85em;
  text-align: center;
  color: var(--COLOR_RED);
  font-family: var(--FONT_MEDIUM);
}