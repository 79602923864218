.tabContainer {
  display: flex;
  margin-top: 2.5rem;
  padding: 10px 0 0 0;
  padding: 0 0 10px 0;
  height: 4.4rem;
  align-content: center;
  justify-content: center;
}

.tabActive {
  width: 50%;
  color: white;
  cursor: pointer;
  padding: 1.1rem 0;
  text-align: center;
  align-content: center;
  border: 2px solid white;
  font-family: var(--FONT_BOLD);
  background: var(--MEDIUM_BLUE);
}

.tabInactive {
  width: 50%;
  padding: 1.1rem 0;
  cursor: pointer;
  text-align: center;
  background: white;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  border: 2px solid var(--DARK_BLUE);
}