.info_complete {
  margin: 25px 0;
  width: calc(100% / 3);
}

.label {
  margin-bottom: .2rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.value {
  font-size: 1.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 570px ) {
  .info_complete {
    width: 50%;
  }
}

@media (max-width: 570px ) {
  .value {
    font-size: 1.1rem;
  }
}