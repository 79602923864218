.LandingStyle {
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("../../images/invertida.jpeg"); */
}

.mainCenter {
  display: grid;
  text-align: center;
  align-content: center;
  height: calc(100vh - 75px);
}

.main {
  padding: 2vh;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
}

.containerSICLogo {
  left: 0;
  bottom: 0;
  padding: 0px 20px;
  position: absolute;
  background: #e2d9d93f;
  border-radius: 0 25px 0 0;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.containerTheFirm {
  bottom: 0;
  left:  calc(50vw - 110px);
  padding: 10px 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textTheFirm {
  font-size: 1.1rem;
  font-weight: 900;
  font-family: auto;
  color: rgb(195, 5, 5);
}

.containerSICLogo > img {
  width: 200px;
}

.Container {
  width: 40%;
  flex-direction: column;
  justify-content: center;
}

.responsiveDatacredito {
  width: 200px;
  margin: auto;
}

.resume {
  width:30%;
}

.container_slider_resume {
  width: 50%;
  margin: auto;
}

@media (max-width: 1100px) {
  .Container {
    width: 50%;
  }

  .resume {
    width:32%;
  }
}

@media (max-width: 990px) {
  .main {
    padding: 1rem;
    margin-top: 2rem;
    display: block;
    text-align: center;
    justify-content: space-evenly;
  }

  .resume,
  .Container {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 990px) {
  .mainCenter {
    height: auto;
    display: block;
    margin-top: 2rem;
  }

  .containerSICLogo {
    border-radius: 0;
    position: initial;
    margin-top: 1.5rem;
    text-align: center;
    padding: 10px 20px 0 10px;
  }

  .containerTheFirm {
    padding: 20px 20px;
    position: initial;
  }
  
  .containerSICLogo > img {
    width: 200px;
  }

  .containerLogoDatacreditoResponsive {
    display: block;
  }
}

@media (max-width: 800px) {
  .LandingStyle {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url("../../images/Landing/Banner_Responsive.jpg"); */
  }
}

@media (max-width: 600px) {
  .resume,
  .Container {
    width: 95%;
  }

  .LandingStyle {
    background-position-x: 20%;
  }
  
}

@media (max-width: 400px) {
  .main {
    padding: 10px;
  }
  .Container {
    width: 100%;
  }
}