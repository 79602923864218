.FormContainer{
  margin: 1rem;
}

.formContainer {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}

.bigInput {
  width: 48%;
  position: relative;
}

.TextForms {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.iconInfo {
  margin-left: 1rem;
}

.SeparateDivs {
  display: flex;
  min-width: 100%;
  margin-top: 2rem;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  justify-content: space-around;
}

.smallForm {
  width: 30%;
}

.colorText {
  margin: 0;
  margin-right: 0.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.container_button {
  margin-bottom: 2rem;
}

.blockTitle {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.iconEye {
  top: 44px;
  right: 20px;
  cursor : pointer;
  position: absolute;
}

.paswordLabel {
  font-size: .8rem;
}

@media (max-width: 800px){
  .SeparateDivs {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .bigInput {
    width: 100%;
    margin-bottom: 1rem;
    
  }
  .smallForm{
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .TextForms {
    margin-top: 0.9rem;
    display: flex;
    margin-bottom: 0.3rem;
    justify-content: center;
    color: var(--COLOR_TEXT);
    font-family: var(--FONT_MEDIUM);
  }
}
