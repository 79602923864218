.view {
  text-align: center;
}

.container_image {
  width: 200px;
  margin: auto;
}

.container_image > img {
  width: 100%;
}

.text {
  margin: 15px 0;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.text_intro {
  margin: 15px 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.container_button {
  width: 50%;
  margin: auto;
}

@media (max-width: 600px){
  .container_button {
    width: 80%;
  }
}

@media (max-width: 400px){
  .container_button {
    width: 90%;
  }
}