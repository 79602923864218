.main {
  width: 70vw;
  margin: auto;
  display: flex;
  height: 100vh;
  text-align: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}

.title {
  /* color: var(--DARK_BLUE); */
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.creditInfo {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.creditInfo p{
  width: 200px;
  text-align: center;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.creditInfo p span{
  font-family: var(--FONT_BOLD);
  color: var(--COLOR_SUCCESS);
}

.image {
  width: 170px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.container_buttons {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.container_buttons button {
  width: 48%;
}

.container :first-child:hover {
  width: 500px !important;
}

@media (max-width: 768px) {
  .main {
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .main {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .main {
    width: 95vw;
  }

  .creditInfo {
    width: 100%;
  }
  
  .creditInfo p{
    width: 45%;
  }

  .container_buttons {
    width: 95%;
  }
}