.header {
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px 20px 0 20px;
  justify-content: space-between;
}

.containerLogo {
  height: 55px;
}

.logo {
  height: 100%;
}

.icon_menu {
  top: 22px;
  left: 20px;
  display: none;
  font-size: 1.7rem;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}

@media ( max-width:768px ) {
  .header {
    justify-content: center;
  }
  
  .icon_menu {
    display: block;
  }
}