
.search{
  width: 100%;
}

.input{
  width: 100%;
  outline: none;
  height: 2.5rem;
  border: 1px solid #CCC;
  border-radius: 5px 5px 0 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.searchInputs{
  position: relative;
  background-color: bisque;
}

.searchIcon {
  position: absolute;
  top: 12px;
  left: 10px;
  color: var(--MEDIUM_BLUE)
}

.dataResult{
  width: 100%;
  height: 15vh;
  padding: 0.5rem;
  text-align: left;
  overflow-y: auto;
  margin-bottom: 1rem;
  border-width: 0.1rem;
  border: 1px solid #CCC;
  background-color: white;
}

.dataItem,
.dataItemSelected{
  cursor: pointer;
  overflow: hidden;
  user-select: none;
  border-radius: 3px;
  white-space: nowrap;
  padding: .2rem .5rem;
  margin-bottom: .2rem;
  text-overflow: ellipsis;
  font-family: var(--FONT_MEDIUM);
}

.dataItemSelected {
  color: white;
  background: var(--MEDIUM_BLUE);
}

.info {
  margin: 0;
  font-size: .9rem;
  font-family: var(--FONT_LIGHT);
}

.infoIcon {
  color: #c7c763;
  margin-right: .5rem;
}

@media (max-width: 600px) {
  .search{
    width: 100%;
  }
  .input{
    width: 100%;
    height: 2.5rem;
  }
}