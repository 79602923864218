.coffe_cup_login {
  width: 35%;
  height: auto;
}

.title {
  font-size: 1em;
  margin-top: 20px;
  text-align: center;
  font-family: var(--FONT_MEDIUM);
}

.logo_login {
  width: 65%;
  height: auto;
  margin-top: 20px;
}

@media (max-width: 768px){
  .title,
  .coffe_cup_login {
    display: none;
  }  
  .logo_login {
    width: 90%;
  }
}