.container {
  display: flex;
  user-select: none;
  align-items: center;
  color: var(--MEDIUM_BLUE);
}

.containerAcount{
  display: flex;
  cursor: pointer;
  margin-left: 3rem;
}

.linkMore {
  padding: 5px 15px;
  border-radius: 8px;
  text-decoration: none;
  background: #f9fafbe0;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.linkMore:hover {
  color: var(--MEDIUM_BLUE);
}

.icon_user {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.text {
  margin: 0;
  font-size: 1.2rem;
  font-family: var(--FONT_BOLD);
}

@media ( max-width:768px ) {
  .container {
    display: none;
  }
}