.body {
  text-align: center;
  position: relative;
}

:global .modal-content {
  border: none;
}

.principal_container {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 115px;
  height: 115px;
  z-index: 9999;
  border-radius: 50%;
  position: relative;
  position: absolute;
  background: var(--DARK_BLUE);
  /* background: white; */
}

.title {
  margin: auto;
  bottom: -30px;
  color: white;
  font-size: 1.3em;
  margin-bottom: 0;
  position: absolute;
  font-family: var(--FONT_BOLD);
}
