.Container {
  height: 100vh;
  overflow: scroll;
}

.home {
  width: 50vw;
  margin: auto;
}

.logo{
  position: absolute;
  height: 60px;
  right: 30px;
  top: 30px;
}

.container_tab {
  width: 60%;
  margin: auto;
}

@media (max-width: 1200px) {
  .home {
    width: 70vw;
  }
}

@media (max-width: 992px) {
  .home {
    width: 85vw;
  }

  .container_tab {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .home {
    width: 95vw;
  }

  .container_tab {
    width: 80%;
  }

  .logo{
    position: relative;
    right: 0px;
    top: 15px;
  }
  .logoContainer{
    display: flex;
    justify-content: center;
   
  }

}

@media (max-width: 600px) {
  .home {
    width: 100vw; 
  }

  .container_tab {
    width: 90%;
  }
}
