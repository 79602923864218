.alignModal {
  background-color: transparent !important;
}

.modalContainer {
  padding: 2rem;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}

.modalIMG {
  width: 35%;
}

.colorText {
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.pText {
  font-size: 0.9rem;
  line-height: 24px;
  text-align: center;
  color: var(--COLOR_TEXT);
}

.container_submit {
  width: 70%;
  margin: auto;
}

.colorTextPhone {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.colorTextVerificacion2 {
  font-size: 1rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.CrediConvenioText {
  font-size: 1rem;
  margin-left: 0.3rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.textContainerCrediConvenio {
  display: flex;
  justify-content: center;
}

.MessageAgain {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.arribaSolicitar {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.SolicitarNuevamente {
  font-size: 1rem;
  cursor: pointer;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.containerImages {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-evenly;
}

.modalImageWarn {
  width: 200px;
}

.pTextWarn {
  line-height: 1.4;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1200px) {
  .modalImageWarn {
    width: 20%;
  }

  .modalImageDataCredit {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .pTextWarn {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .pTextWarn {
    font-size: 1.2rem;
  }
}

@media (max-width: 575px) {
  .modalContainer {
    padding: 2rem 1rem;
  }

  .container_submit {
    width: 100%;
  }
}