.modal_body {
  padding: 2rem;
  position: relative;
}

.body {
  text-align: center;
}

.modal_title {
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--FONT_BOLD);
}

.container_image {
  margin-bottom: 1rem;
  height: 70px;
}

.imageLogo {
  height: 100%;
}

.text_info {
  margin: 1rem 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.container_buttons {
  display: flex;
}

.container_button {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

.infoCredit {
  display: flex;
  justify-content: space-evenly;
}

.labelTitle {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.labelValue {
  font-family: var(--FONT_BOLD);
}

@media (max-width: 600px) {
  .container_button {
    width: 75% !important;
  }
}

@media (max-width: 500px) {
  .container_button {
    width: 100% !important;
  }

  .container_buttons {
    display: block;
  }
}
