.view {
  text-align: center;
}

.home {
  width: 55vw;
  margin: auto;
  padding: 2rem 0;
}

.home::-webkit-scrollbar {
  display: none;
}

.container_image {
  width: 200px;
  margin: auto;
}

.container_image > img {
  width: 100%;
}

.text {
  margin: 15px 0;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.textMora {
  color: red;
  margin: 10px 0;
  font-size: 1rem;
  font-family: var(--FONT_MEDIUM);
}

.container_buttons {
  width: 35vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.container_link {
  width: 35vw;
  margin: auto;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 15px;
  border: 3px solid white;
  background: var(--MEDIUM_BLUE);
}

.link,
.link > p{
  color: white;
  text-decoration: none;
}

.title {
  font-size: 1.2rem;
  font-family: var(--FONT_BOLD);
}

.text_link {
  margin-bottom: 1rem;
  font-family: var(--FONT_MEDIUM);
}

.click {
  font-size: .9rem; 
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1550px ) {
  .container_link,
  .container_buttons {
    width: 100%;
  }
}

@media (max-width: 1078px ) {
  .home {
    width: 74vw;
  }

  .container_link,
  .container_buttons {
    width: 100%;
  }
}

@media (max-width: 820px ) {
  .home {
    width: 80vw;
  }

  .container_buttons {
    /* width: 65vw; */
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* .container_link {
    width: 65vw;
  } */

  .container_image{
    margin-top: 3rem;
  }
  .text{
    font-size: 1.1rem;
  }
}

@media (max-width: 700px ) {
  .home {
    width: 90vw;
  }

  /* .container_buttons {
    width: 80vw;
  }
  
  .container_link {
    width: 80vw;
  } */
}

@media (max-width: 570px ) {
  .home {
    width: 95vw;
  }

  .container_image {
    width: 150px;
  }

  .container_buttons {
    /* width: 95vw; */
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .container_link {
    width: 95vw;
  }
}