.containerTabComplete {
  margin: 1rem 0;
  text-align: center;
}

.title {
  font-family: var(--FONT_BOLD);
}

.containerControlTab {
  display: flex;
  align-items: center;
  font-family: var(--FONT_BOLD);
  justify-content: space-between;
}

.tabActive {
  background-color: blue;
  height: 3.8rem;
  padding: 1.1rem 0;
  width: 50%;
  color: white;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
  font-family: var(--FONT_BOLD);
  background: var(--MEDIUM_BLUE);
}

.tabInactive {
  width: 50%;
  height: 3.8rem;
  padding: 1.1rem 0;
  cursor: pointer;
  text-align: center;
  background: white;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  border: 2px solid var(--DARK_BLUE);
}

.containerInput {
  text-align: center;
  margin-bottom: 2rem;
}

.containerInput > label {
  width: 100%;
  margin: 2rem 0 1rem 0;

}

.containerInput > div {
  width: 100%;
  margin-top: 1rem;
}

.note {
  color: var(--COLOR_RED);
}