.principal_container {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.header {
  height: 20%;
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
}
.content {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.logo {
  width: 40%;
  object-fit: contain;
}
.not_found {
  height: 60%;
  margin-bottom: 20px;
  object-fit: contain;
}
.title {
  color: var(--MEDIUM_BLUE);
  font-size: 1.4em;
  line-height: 20px;
  text-align: center;
  font-family: var(--FONT_BOLD);
}
.subtitle {
  color: var(--MEDIUM_BLUE);
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.text {
  color: var(--MEDIUM_BLUE);
  font-size: 1em;
  text-align: center;
  font-family: var(--FONT_MEDIUM);
}
.btn {
  width: 511px;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 1.2em;
  min-height: 3rem;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  background-color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  box-shadow: 0px 1px 3px 0px rgba(197,190,190,0.75);
}
.btn:hover {
  background-color: var(MEDIUM_BLUE);
}
@media (max-width: 768px) {
  .principal_container { font-size: 15px; }
  .content { height: 85%; }
}
@media (max-width: 600px) {
  .btn { width: 90%; }
}
@media (max-width: 425px) {
  .not_found {
    width: 100%;
    height: 50%;
  }
  .btn { width: 100%; }
}