.Container {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
}

.logodisbur {
  width: 8rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.title {
  font-size: 1.4rem;
  text-align: center;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.subtitle {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 600px) {
  .Container {
    width: 100%;
    margin: 0px;
    margin-bottom: 0px;
  }

  .title {
    font-size: 1rem;
  }
  .subtitle {
    font-size: 70%;
  }
}
