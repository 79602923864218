.buttoncheck {
  width: 100%;
  outline: none;
  color: white;
  cursor: pointer;
  user-select: none;
  font-size: 1.2rem;
  min-height: 3.5rem;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  font-family: var(--FONT_BOLD);
  background-color: var(MEDIUM_BLUE);
  box-shadow: 0px 1px 3px 0px rgba(197,190,190,0.75);
}

.buttoncheck:hover {
  background-color: var(MEDIUM_BLUE);
}
