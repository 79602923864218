.principal_container {
  font-size: 16px;
  overflow-y: auto;
  margin-top: 2rem;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 90px);
}
/* history cards */
.container_cards {
  display: flex;
  flex-wrap: wrap;  
}
.card_history {
  width: 18.5%;
  display: flex;
  padding: 15px;
  border-radius: 7px;
  margin: 0 20px 20px 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 2px solid #d9d9d9;
}
.info_card {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.title_card {
  width: 100%;
  color: #333;
  font-size: 1rem;
  overflow: hidden;
  margin-bottom: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: var(--FONT_MEDIUM);
}
.container_value {
  display: flex;
  margin-bottom: 5px;
}
.label {
  font-size: 1rem;
  color: #015c83;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}
.paid {
  font-size: 1rem;
  margin-left: 7px;
  color: #4df591;
  margin-bottom: 0;
  font-family: var(--FONT_MEDIUM);
}
.value {
  color: #333;
  font-size: 1rem;
  margin-left: 7px;
  margin-bottom: 0;
  font-family: var(--FONT_MEDIUM);
}
.right_chevron_icon {
  font-size: 1.7em;
  color: #015c83;
}
.right_chevron_icon:hover {
  cursor: pointer;
}

.containerDefault {
  width: 100%;
  display: grid;
  height: calc(100% - 48px);
  grid-template-columns: repeat(3, 1fr);
}

.containerDefault > img {
  width: 80%;
  grid-row: 1;
  margin: auto;
  grid-column: 2 / 3;
}

/* response */
@media(max-width: 1352px) {
  .card_history { width: 18%; }
}
@media(max-width: 1200px) {
  .card_history { width: 23%; }
}
@media(max-width: 1039px) {
  .card_history { width: 31%; }
}
@media(max-width: 896px) {
  .card_history { width: 47%; }
}
@media(max-width: 706px) {
  .card_history { width: 46%; }
}
@media(max-width:539px) {
  .card_history { width: 100%; margin-right: 0; }
  .title_card { margin-bottom: 17px; }
}