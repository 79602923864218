.input {
  margin-right: 1rem;
}

.Container > label {
  margin: .6rem 0;
  user-select: none;
  align-items: center;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.textColorTerms{
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  cursor: pointer;
  text-decoration: none;
}

.iconChecked,
.icon {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.iconChecked {
  color: var(--MEDIUM_BLUE);
}

.icon {
  color: #989696;
}

.checkboxDisplay{
  display: flex;
  justify-content: center;
}
.checkboxDisplay > *{
  margin-bottom: 0.5rem;
}

@media (max-width:800px){
  .Container > label {
    display: flex;
    text-align: start;
    font-size: 1rem;
    align-items: flex-start;
    margin-left: 8%;
  }
  .textColorTerms{
    color: var(--MEDIUM_BLUE);
    font-family: var(--FONT_BOLD);
    text-decoration: none;
  }
}