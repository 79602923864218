.logoCrediConvenio {
  height: 65px;
  padding: 5px;
}

.logo {
  height: 100%;
}

@media (max-width: 768px){
  .logoCrediConvenio {
    text-align: center;
  }
}