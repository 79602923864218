.container {
  padding: 4rem 0 0 0;
  text-align: center;
  margin-bottom: 2.5rem;
}

.TextColor {
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.simpleStyle {
  font-size: 1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}


@media (max-width: 600px){
  .TextColor{
    font-size: 1rem;
    padding: 0.5rem;
  }

  .img{
    width: 8rem;
  }
  
}
