.Container{
  width: 45vw;
  margin: auto;
  min-height: 100vh;
  text-align: center;
}

@media (max-width: 1445px) {
  .Container{
    width: 55vw;
  }
}

@media (max-width: 1200px) {
  .Container{
    width: 65vw;
  }
}

@media (max-width: 980px) {
  .Container{
    width: 75vw;
  }
}

@media (max-width: 880px) {
  .Container{
    width: 85vw;
  }
}

@media (max-width: 600px) {
  .Container{
    width: 90vw;
  }
}
