.sidebar {
  padding: 20px;
}

.title {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.containerLogo {
  height: 60px;
}

.logo {
  height: 100%;
}

.times {
  top: -15px;
  right: 10px;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  color: var(--COLOR_TEXT)
}

.info_user {
  display: grid;
  height: calc(100vh - 68px);
  align-content: space-between;
}

.container_user {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
}

.icon_user {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--MEDIUM_BLUE)
}

.name_user {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.welcome {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.links_user {
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  text-decoration: none;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.sesion {
  bottom: 20px;
  display: flex;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  align-items: center;
  text-decoration: none;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

.icon_sesion {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--COLOR_TEXT)
}