.Container {
  padding-top: 2rem;
}

.introText {
  margin: 0;
  font-size: 0.9rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.label {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Divs {
  max-width: 50%;
  margin-bottom: 1.3rem;
 
}

.smallLeter {
  font-size: 0.7rem;
  margin-top: -0.7rem;
}

.ContainerInput {
  width: 40%;
  margin: .5rem auto 1rem auto;
}

.iconChecked,
.icon {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.iconChecked {
  color: var(--MEDIUM_BLUE);
}

.icon {
  color: #989696;
}

.container_check {
  margin: 0.3rem 0;
  font-family: var(--FONT_LIGHT);
  margin-bottom: 1.5rem;
}

.link,
.link:hover {
  text-decoration: none;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.countdown {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.request_again {
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.request_again:hover {
  cursor: pointer;
}

@media (max-width: 630px) {
  .ContainerInput {
    width: 60%;
  } 
}

@media (max-width: 500px) {
  .ContainerInput {
    width: 80%;
  }

  .container_check {
    width: 100%;
    margin: .7rem 0;
    text-align: left;
  }
}

@media (max-width: 400px) {
  .ContainerInput {
    width: 90%;
  }
}
