@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

* {
  font-family: var(--FONT_LIGHT);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"), url(./fonts/Raleway-Bold.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-Medium";
  src: local("Raleway-Medium"),
    url(./fonts/Raleway-Medium.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-Light";
  src: local("Raleway-Light"), url(./fonts/Raleway-Light.ttf) format("truetype");
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* overflow: hidden; */
  --COLOR_DANGER: red;
  --COLOR_ALERT: #e4e129;
  --FONT_BOLD: "Raleway-Bold";
  --FONT_LIGHT: "Raleway-Light";
  --FONT_MEDIUM: "Raleway-Medium";
  --COLOR_DISABLED_LIGHT: #f3f3f3;

  --COLOR_TEXT: #747171;
  --COLOR_RED: #dc3545;
  --DARK_BLUE: #015c83;
  --MEDIUM_BLUE: #1c64d4;
  --LIGHT_BLUE: #9cbff4;
  --COLOR_DISABLED: #ccc;
  --COLOR_ORANGE: #eabc31;
  --COLOR_SUCCESS: #4df591;
}

.MuiSlider-rail {
  width: 0% !important;
}
.MuiSlider-mark {
  width: 0% !important;
}

.MuiSlider-markLabel {
  margin-top: -10px;
}

.modal-content {
  border-radius: 20px !important;
}
