.Container {
  min-height: 4.5rem;
  margin-bottom: -1rem;
}

.inputcheck {
  width: 100%;
  outline: none;
  text-align: center;
  min-height: 3rem;
  border-radius: 10vh;
  border-style: solid;
  font-family: var(--FONT_LIGHT);
  border-color: rgb(184, 184, 184);
}

.error {
  font-size: 0.85em;
  text-align: center;
  color: var(--COLOR_RED);
  font-family: var(--FONT_MEDIUM);
}
