.SmallDivsContainer {
  display: flex;
  justify-content: space-between;
}

.SmallDivs {
  width: 48%;
  margin-bottom: 2rem;
}

.TextInput {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 600px){
  .SmallDivs {
    width: 49%;
  }
}

@media (max-width: 400px){
  .SmallDivsContainer {
    display: block;
  }

  .SmallDivs {
    width: 100%;
  }
}
