.dataCredit {
  top: 80px;
  width: 200px;
  right: 1.5rem;
  position: absolute;
  text-align: center;
  color: var(--COLOR_RED);
}

.ImageDataCredit {
  width: 70%;
}

.home {
  width: 50vw;
  margin: auto;
  text-align: center;
}

.LogoUno {
  right: 2%;
  margin: 1rem 0;
  cursor: pointer;
  position: absolute;
}

.textAlert {
  margin: -2rem 0 2rem 0;
  font-family: var(--FONT_MEDIUM);
}

.textAlert > strong {
  color: var(--COLOR_RED);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1270px) {
  .home {
    width: 70vw;
  }
}

@media (max-width: 894px) {
  .home {
    width: 80vw;
  }
}

@media (max-width: 800px) {
  .home {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .infoCredito {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}