.biweekly_fee {
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  font-size: 25px;
}

.biweekly_fee_responsive {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.Container {
  color: #747171;
  border-radius: 15px;
  background: #e2d9d93f;
  border: 3px solid white;
  padding: 15px 10px 10px 10px;
  margin: 0.5rem auto 2rem auto;
}

.container_15 {
  border-radius: 15px;
  background: #ffffff8a;
  border: 1px solid white;
  padding: 10px 10px 5px 10px;
  margin: 0.5rem auto 2rem auto;
}

.Container > * {
  display: flex;
  line-height: 28px;
  align-items: center;
  justify-content: space-between;
  font-family: var(--FONT_BOLD);
}

.Container > div > h5 {
  font-size: 1.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

.Container > div > p {
  margin: 0;
  font-family: var(--FONT_BOLD);
  /* font-family: var(--FONT_LIGHT); */
}

.title {
  margin: 0;
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.tooltip {
  width: 200px;
  line-height: .9rem;
  font-family: var(--FONT_MEDIUM);
}

.icon {
  outline: none;
}

@media (max-width: 600px) {
  .Container > div > p {
    font-size: 0.9rem;
  }

  .Container > div > h5 {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .Container > div > p {
    font-size: 0.8rem;
  }

  .Container > div > h5 {
    font-size: 1.1rem;
  }
}
