.container_toast {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.icon_toast {
  font-size: 25px;
  min-width: 27px;
  margin-right: 10px;
  border-radius: 50% !important;
}

.container_toast p {
  font-size: 1em;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}
