.alignModal {
  display: flex;
}

.modalContainer {
  margin: auto;
  padding: 2rem 1rem;
  text-align: center;
}

.modalIMG {
  width: 30%;
}

.colorLightBlue {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.textInfo {
  margin: 0;
  color: #999;
  font-family: var(--FONT_BOLD);
}
