.modal_body {
  text-align: center;
  padding: 1rem 2rem 2rem 2rem;
}

.close {
  top: 10px;
  right: 15px;
  font-size: 1.3rem;
  position: absolute;
  color: VAR(--COLOR_TEXT)
}

.modal_title {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.contanerInputsGrouped {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.containerInput {
  width: 48%;
  text-align: left;
}

.containerInputSmall {
  width: 31%;
  text-align: left;
}

.label {
  margin: .3rem;
  font-size: .8rem;
  color: VAR(--COLOR_TEXT);
  font-family: VAR(--FONT_LIGHT);
}

.label > span {
  color: var(--MEDIUM_BLUE);
  font-family: VAR(--FONT_BOLD);
}

.containerButton {
  width: 50%;
  margin: auto;
}

.label_error {
  font-size: 0.8em;  
  margin-top: -1rem;
  margin-bottom: 10px;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}


@media (max-width: 992px){
  .contanerInputsGrouped {
    justify-content: space-around;
  }

  .containerInputSmall {
    width: 48%;
    text-align: left;
  }
}

@media (max-width: 500px){
  .modal_body {
    padding: 2rem 1rem 1rem 1rem;
  }

  .containerInput,
  .containerButton,
  .containerInputSmall {
    width: 90%;
  }
}

@media (max-width: 400px){
  .containerInput,
  .containerButton,
  .containerInputSmall {
    width: 100%;
  }
}