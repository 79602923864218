.ContainerLong {
  min-width: 50%;
  max-width: 50%;
  margin: auto 0 auto;
}

.NameText {
  margin-bottom: 0;
  font-size: 0.8rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.Data {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.longData {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
  width: 100%;
}

@media (max-width: 356px) {
  .Container,
  .ContainerLong {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}