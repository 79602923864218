.container {
  width: 45%;
  color: white;
  padding: 20px 0;
  cursor: pointer;
  margin: 0 0 2rem 0;
  text-align: center;
  border-radius: 15px;
  text-decoration: none;
  justify-content: center;
  border: 3px solid white;
}

.container:hover {
  color: white;
}

.icon {
  font-size: 2.7rem;
}

.text {
  margin: 0;
  font-family: var(--FONT_BOLD);
}

@media (max-width: 750px ) {
  .container {
    width: 48%;
  }
}