.title {
  font-size: 1.5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.text {
  margin-bottom: 1rem;
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 800px) {
  .title {
    color: var(--MEDIUM_BLUE);
    font-size: 1.3rem;
  }
  
  .text {
    color: var(--MEDIUM_BLUE);
  }
}
