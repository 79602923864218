.SliderL {
  margin-bottom: 1rem;
  background-color: white;
  padding: 0.35rem !important;
  border-radius: 10vh !important;
  /* color: var(--MEDIUM_BLUE) !important; */
  color: var(--MEDIUM_BLUE) !important;
}

.textModal,
.text {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
  font-family: var(--FONT_BOLD);
  color: var(--COLOR_TEXT) !important;
}

.TextSliderModal,
.TextSlider {
  color: var(--LIGHT_BLUE) !important;
  font-family: var(--FONT_BOLD) !important;
}

.marksModeModal,
.marksMode{
  width: 99%;
  display: flex;
  margin-left: 1%;
  margin-top: -0.4rem;
  color: var(--FONT_BOLD);
  justify-content: space-between;
}

.marksMode2{
  width: 100%;
  display: flex;
  margin-top: -0.4rem;
  color: var(--COLOR_TEXT) !important;
  justify-content: space-between;
}
.marksMode1{
  width: 100%;
  display: flex;
  color: var(--COLOR_TEXT) !important;
  margin-left: -10%;
  margin-top: -0.4rem;
  justify-content: space-between;
}
