.Container {
  min-height: 100vh;
}

.container_info_credit {
  width: 50vw;
  margin: auto;
  text-align: center;

}

@media (max-width: 990px) {
  .container_info_credit {
    width: 80vw;
  } 
}

@media (max-width: 630px) {
  .container_info_credit {
    width: 90vw;
  }
}