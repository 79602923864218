.Container {
  margin: auto;
  max-width: 85%;
}

.containerInfo {
  margin: 2.5rem 0;
}

.Divs {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}

.title {
  margin: 0;
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.DivsDifference {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-evenly;
}

.containerButton {
  width: 50%;
  margin: auto;

}

@media (max-width: 500px) {
  .Container {
    max-width: 100%;
  }
  .Divs {
    margin-left: 0;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .Divs>*{
    width: 10rem;
  }
  .DivsDifference {
    display: flex;
    flex-direction: column;
    margin-top: -4.7rem;
  }
  #DivResponsive1{
    margin: 1rem 13rem 0rem 2rem;
  }
  #DivResponsive2{
    margin: 0rem -2rem 1.5rem 8rem;
  }

  .containerButton {
    width: 100%;
  }
}

@media (max-width: 347px) {
  .Divs {
    margin-bottom: 0rem;
  }
  #DivResponsive1{
    width: 100%;
    margin: 0rem;
  }
  #DivResponsive2{
    margin: 4.7rem 0rem 0rem 0rem;
  }
}

