.container_recover_password {
  display: grid;
  height: 100vh;
  overflow: auto;
  align-content: center;
  justify-content: center;
}

.form_passwords {
  width: 40vw;
  margin: auto;
  text-align: center;
}

.icon_password {
  width: 200px;
}

.form_passwords > h3 {
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.developer_logo {
  width: 200px;
  right: 2rem;
  bottom: 2rem;
  position: absolute;
}

.container_input {
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
}

.label_error {
  width: 100%;
  bottom: -2.1rem;
  font-size: 0.9em;
  position: absolute;
  margin-top: -0.9rem;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}

@media (max-width: 1024px) {
  .form_passwords {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .form_passwords {
    width: 60vw;
  }
}

@media (max-width: 425px) {
  .form_passwords {
    width: 90vw;
  }
}
