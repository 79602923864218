.modal_body {
  padding: 2rem;
  position: relative;
}

.body {
  text-align: center;
}

.container_image {
  margin-bottom: 1rem;
  height: 70px;
}

.image_logo {
  height: 100%;
}

.modal_title {
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--FONT_BOLD);
}

.text_info {
  margin: 1rem 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.label {
  margin: 1rem 0 0 0;
}

.inputToPay {
  width: 50%;
  outline: none;
  padding: 7px 15px;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 20px;
  border: 1px solid #999;
}

.container_button {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

.infoCredit {
  display: flex;
  justify-content: space-evenly;
}

.labelTitle {
  cursor: pointer;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

.labelTitle:hover {
  text-decoration: underline;
}

.labelValue {
  font-family: var(--FONT_BOLD);
}

@media (max-width: 600px) {
  .container_button {
    width: 75% !important;
  }
}

@media (max-width: 500px) {
  .container_button {
    width: 100% !important;
  }
}
