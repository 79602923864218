.container_info_credit {
  width: 50%;
  margin: auto;
}

.content {
  width: 30%;
  margin: 2.5rem auto;
}

@media (max-width: 1250px) {
  .content {
    width: 40%;
  }
}

@media (max-width: 992px) {
  .content {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .content {
    width: 65%;
  }
}

@media (max-width: 550px) {
  .content {
    width: 75%;
  }
  .container_info_credit {
    width: 75%;
  }
}

@media (max-width: 450px) {
  .content {
    width: 95%;
  }
  .container_info_credit {
    width: 95%;
  }
}
