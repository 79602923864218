.title {
  margin: 0;
  display: flex;
  justify-content: center;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

.body {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.close {
  top: 10px;
  right: 15px;
  font-size: 1.3rem;
  position: absolute;
  color: VAR(--COLOR_TEXT);
}

.simulator {
  width: 50%;
  padding: 1rem;
  text-align: center;
}

.resume {
  width: 50%;
}

@media (max-width: 991px) {
  .body {
    display: block;
  }

  .resume,
  .simulator {
    width: 80%;
    margin: auto;
    padding: 1rem 0;
  }

  .resume {
    margin-bottom: 1rem;
  }

  .container_button {
    left: 13%;
    right: 13%;
    bottom: 20px;
    position: absolute;
  }
}

@media (max-width: 469px) {
  .resume,
  .simulator {
    width: 100%;
  }
}
