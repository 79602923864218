.InfoContainer {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.info {
  margin: 0;
  text-align: center;
  width: calc(100% / 4)
}

.label {
  margin: 0;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.value {
  margin: 0;
  font-size: 1.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.remaining_value{
  margin: 0;
  font-size: 1.3rem;
  font-family: var(--FONT_BOLD);
}

.disburse_value{
  margin: 0;
  font-size: 1.3rem;
  color: var(--COLOR_SUCCESS);
  font-family: var(--FONT_BOLD);
}

.MontoYdesembolso{
  display: flex;
  justify-content: space-between;
}
.MontoYdesembolso>*{
  margin-top: 1.6rem;
  width: 15rem;

  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {  
  .info {
    width: 50%;
    margin-bottom: 2rem;
  }  
  .MontoYdesembolso>*{
    margin-top: 0.8rem;
    width: 15rem;
  
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 745px) {  
  .InfoContainer {
    width: 100%;
  }  
}