.Container {
  width: 100%;
}

.container_input {
  width: 80%;
  margin: auto auto 2rem auto;
}

.TextInput {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  margin-top: 2rem;
}

.divInfoContainer {
  text-align: center;
}

@media (max-width: 500px) {
  .container_input {
    width: 90%;
  }
  .divInfoContainer {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
}

@media (max-width: 400px) {
  .container_input {
    width: 100%;
  }
}
