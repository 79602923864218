.custom_select {  
  position: relative;
  min-height: 4.5rem;
  margin-bottom: -1rem;
}

.btn_select {  
  width: 100% !important;
  color: #333 !important;
  display: flex !important;
  outline: none !important;
  font-size: 1em !important;
  padding: 0 20px !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  line-height: 17px !important;
  text-align: center !important;
  border-radius: 10vh !important;
  text-transform: none !important;
  background-color: #fff !important;
  justify-content: space-between !important;
  font-family: var(--FONT_LIGHT) !important;
  border: 2px solid rgb(184, 184, 184) !important;
}

.btn_select :global .MuiButton-label {
  justify-content: space-between !important;
}

.icon_select {
  color: #999 !important;
  font-size: 16px !important;
}

.options_select {
  z-index: 1;
  width: 330px;
  height: 150px;
  bottom: -135px;
  overflow-y: auto;
  padding: 8px 12px;
  position: absolute;
  border-radius: 7px;
  background-color: #fff;  
}

.default_text {
  color: #666;
  margin-bottom: 0;
  padding-top: 63px;
  text-align: center;
  font-family: VAR(--FONT_LIGHT);
}

.option_select {
  color: #666;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-family: VAR(--FONT_LIGHT);
}

.option_select:hover {
  cursor: pointer;
}

.container_selected_options {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  margin-top: 15px;
}

.item_selected_option {
  display: flex;
  max-height: 29px;
  position: relative;
  margin-right: 10px;  
  margin-bottom: 10px;
  border-radius: 20px;
  align-items: center;
  padding: 5px 30px 5px 12px;
  background-color: VAR(--COLOR_PURPLE);
}

.option_name {
  width: 100%;
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 0;  
  font-family: VAR(--FONT_BOLD);
}

.error {
  color: red;
  font-weight: bold;
  text-align: center;
}