.principal_content :global .modal-content {
  border-radius: 13px;
  background-color: white !important;
}
.principal_content :global .modal-body {
  padding: 20px 20px 30px;
} 
.close_icon {
  top: 15px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  color: var(--COLOR_TEXT);
}
.principal_container {
  display: flex;
  font-size: 16px;
  align-items: center;
  flex-direction: column;
}
.title {
  font-size: 1.3em;
  overflow: hidden;
  margin-bottom: 0px;
  white-space: nowrap;
  color: var(--DARK_BLUE);
  text-overflow: ellipsis;
  /* width: calc(100% - 80px); */
  font-family: var(--FONT_BOLD);
}
.paid {
  font-size: 1em;
  font-weight: bold;
  font-family: var(--FONT_MEDIUM);
}
/* installments */
.container_installments {
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 20px;
}
.container_row {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.installments_title_table {
  font-size: 0.9em;
  text-align: center;
  width: calc(100% / 6);
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}
.installments_value_table {
  width: calc(100% / 6);
  text-align: center;
}
.value {
  font-size: 1em;
  font-family: var(--FONT_MEDIUM);
}

.valuesTotal {
  width: 100%;
  text-align: end;
}

.totalToPay{
  margin: 0;
  font-size: 0.9rem;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

.grandTotal>span,
.totalToPay>span{
  font-size: 1.1em;
  color: var(--COLOR_DANGER);
  font-family: var(--FONT_MEDIUM);
}

.grandTotal {
  margin: 0;
  font-size: 1.1rem;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 991px) {
  .container_installments {
    width: 100%;
    overflow-x: scroll;
  }

  .container_row {
    width: 700px;
  }
}

@media (max-width: 768px) {
  .container_btn { width: 80%; }
  .container_state { width: 80%; }
  .principal_container { font-size: 15px; }
  .dates, .installments_date { width: 60%; }
  .installments, .installments_value { width: 40%; }
}