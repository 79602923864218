@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap);
* {
  font-family: var(--FONT_LIGHT);
}

*::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

*::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background: #ccc;
}

*::-webkit-scrollbar-track {
  border-radius: 10px;
  background: transparent;
}

@font-face {
  font-family: "Raleway-Bold";
  src: local("Raleway-Bold"), url(/static/media/Raleway-Bold.d1a96e2a.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-Medium";
  src: local("Raleway-Medium"),
    url(/static/media/Raleway-Medium.1af9f1e9.ttf) format("truetype");
}
@font-face {
  font-family: "Raleway-Light";
  src: local("Raleway-Light"), url(/static/media/Raleway-Light.f25e9b3a.ttf) format("truetype");
}

body {
  margin: 0;
  min-height: 100vh;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  /* overflow: hidden; */
  --COLOR_DANGER: red;
  --COLOR_ALERT: #e4e129;
  --FONT_BOLD: "Raleway-Bold";
  --FONT_LIGHT: "Raleway-Light";
  --FONT_MEDIUM: "Raleway-Medium";
  --COLOR_DISABLED_LIGHT: #f3f3f3;

  --COLOR_TEXT: #747171;
  --COLOR_RED: #dc3545;
  --DARK_BLUE: #015c83;
  --MEDIUM_BLUE: #1c64d4;
  --LIGHT_BLUE: #9cbff4;
  --COLOR_DISABLED: #ccc;
  --COLOR_ORANGE: #eabc31;
  --COLOR_SUCCESS: #4df591;
}

.MuiSlider-rail {
  width: 0% !important;
}
.MuiSlider-mark {
  width: 0% !important;
}

.MuiSlider-markLabel {
  margin-top: -10px;
}

.modal-content {
  border-radius: 20px !important;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.App {
  background-color: #fff;
}


.Bubble_container__1y0iO {
  z-index: 1;
  right: 50px;
  width: 65px;
  bottom: 50px;
  height: 65px;
  display: grid;
  color: white;
  cursor: pointer;
  position: fixed;
  -webkit-user-select: none;
          user-select: none;
  font-size: 2.8rem;
  border-radius: 50%;
  align-items: center;
  align-content:center;
  background: #25D366;
  justify-content:center;
}

.Bubble_container__1y0iO:hover {
  color: white;
}

@media (max-width: 600px) {
  .Bubble_container__1y0iO {
    width: 55px;
    right: 15px;
    bottom: 15px;
    height: 55px;
    font-size: 2.3rem;
  }
}
.Home_view__2I86p {
  text-align: center;
}

.Home_home__233wH {
  width: 55vw;
  margin: auto;
  padding: 2rem 0;
}

.Home_home__233wH::-webkit-scrollbar {
  display: none;
}

.Home_container_image__3a1d5 {
  width: 200px;
  margin: auto;
}

.Home_container_image__3a1d5 > img {
  width: 100%;
}

.Home_text__1s48z {
  margin: 15px 0;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Home_textMora__1_mRi {
  color: red;
  margin: 10px 0;
  font-size: 1rem;
  font-family: var(--FONT_MEDIUM);
}

.Home_container_buttons__fhdbD {
  width: 35vw;
  margin: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.Home_container_link__qMnDj {
  width: 35vw;
  margin: auto;
  padding: 20px 0;
  cursor: pointer;
  border-radius: 15px;
  border: 3px solid white;
  background: var(--MEDIUM_BLUE);
}

.Home_link__3m9Bb,
.Home_link__3m9Bb > p{
  color: white;
  text-decoration: none;
}

.Home_title__usulO {
  font-size: 1.2rem;
  font-family: var(--FONT_BOLD);
}

.Home_text_link__20OI5 {
  margin-bottom: 1rem;
  font-family: var(--FONT_MEDIUM);
}

.Home_click__1cmjN {
  font-size: .9rem; 
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1550px ) {
  .Home_container_link__qMnDj,
  .Home_container_buttons__fhdbD {
    width: 100%;
  }
}

@media (max-width: 1078px ) {
  .Home_home__233wH {
    width: 74vw;
  }

  .Home_container_link__qMnDj,
  .Home_container_buttons__fhdbD {
    width: 100%;
  }
}

@media (max-width: 820px ) {
  .Home_home__233wH {
    width: 80vw;
  }

  .Home_container_buttons__fhdbD {
    /* width: 65vw; */
    margin-top: 1.5rem;
    margin-bottom: 1rem;
  }
  
  /* .container_link {
    width: 65vw;
  } */

  .Home_container_image__3a1d5{
    margin-top: 3rem;
  }
  .Home_text__1s48z{
    font-size: 1.1rem;
  }
}

@media (max-width: 700px ) {
  .Home_home__233wH {
    width: 90vw;
  }

  /* .container_buttons {
    width: 80vw;
  }
  
  .container_link {
    width: 80vw;
  } */
}

@media (max-width: 570px ) {
  .Home_home__233wH {
    width: 95vw;
  }

  .Home_container_image__3a1d5 {
    width: 150px;
  }

  .Home_container_buttons__fhdbD {
    /* width: 95vw; */
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .Home_container_link__qMnDj {
    width: 95vw;
  }
}
.header_header__HZ6Fg {
  display: flex;
  position: relative;
  align-items: center;
  padding: 20px 20px 0 20px;
  justify-content: space-between;
}

.header_containerLogo__D92LI {
  height: 55px;
}

.header_logo__3iSgw {
  height: 100%;
}

.header_icon_menu__1erNJ {
  top: 22px;
  left: 20px;
  display: none;
  font-size: 1.7rem;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}

@media ( max-width:768px ) {
  .header_header__HZ6Fg {
    justify-content: center;
  }
  
  .header_icon_menu__1erNJ {
    display: block;
  }
}
.Account_container__15C_7 {
  display: flex;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  color: var(--MEDIUM_BLUE);
}

.Account_containerAcount__3UDoe{
  display: flex;
  cursor: pointer;
  margin-left: 3rem;
}

.Account_linkMore__11-Su {
  padding: 5px 15px;
  border-radius: 8px;
  text-decoration: none;
  background: #f9fafbe0;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Account_linkMore__11-Su:hover {
  color: var(--MEDIUM_BLUE);
}

.Account_icon_user___xp8I {
  font-size: 1.5rem;
  margin-right: 0.5rem;
}

.Account_text__2S9jc {
  margin: 0;
  font-size: 1.2rem;
  font-family: var(--FONT_BOLD);
}

@media ( max-width:768px ) {
  .Account_container__15C_7 {
    display: none;
  }
}
.Sidebar_sidebar__2n2bb {
  padding: 20px;
}

.Sidebar_title__UrS-M {
  width: 100%;
  display: flex;
  position: relative;
  align-items: center;
  justify-content: space-between;
}

.Sidebar_containerLogo__28fy3 {
  height: 60px;
}

.Sidebar_logo__droBj {
  height: 100%;
}

.Sidebar_times__1gZRm {
  top: -15px;
  right: 10px;
  font-size: 3rem;
  cursor: pointer;
  position: absolute;
  color: var(--COLOR_TEXT)
}

.Sidebar_info_user__25wrs {
  display: grid;
  height: calc(100vh - 68px);
  align-content: space-between;
}

.Sidebar_container_user__wv5Ex {
  display: flex;
  margin-top: 1.5rem;
  align-items: center;
}

.Sidebar_icon_user__28nm0 {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--MEDIUM_BLUE)
}

.Sidebar_name_user__1oyJx {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.Sidebar_welcome__1nieg {
  font-size: 1.2rem;
  margin-bottom: .5rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.Sidebar_links_user__11noP {
  display: flex;
  font-size: 1.3rem;
  align-items: center;
  text-decoration: none;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Sidebar_sesion__jf8MF {
  bottom: 20px;
  display: flex;
  cursor: pointer;
  font-size: 1.3rem;
  position: absolute;
  align-items: center;
  text-decoration: none;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

.Sidebar_icon_sesion__o-PL5 {
  font-size: 2rem;
  margin-right: 1rem;
  color: var(--COLOR_TEXT)
}
.ModalAlert_modal_body__Gq08h {
  padding: 2rem;
  position: relative;
}

.ModalAlert_body__MIRTU {
  text-align: center;
}

.ModalAlert_modal_title__lnZiO {
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--FONT_BOLD);
}

.ModalAlert_container_image__2Bmp5 {
  margin-bottom: 1rem;
  height: 70px;
}

.ModalAlert_imageLogo__1hMBt {
  height: 100%;
}

.ModalAlert_text_info__bePvg {
  margin: 1rem 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.ModalAlert_container_buttons__2i9cY {
  display: flex;
}

.ModalAlert_container_button__1FkVf {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

.ModalAlert_infoCredit__rfSBM {
  display: flex;
  justify-content: space-evenly;
}

.ModalAlert_labelTitle__1l1nk {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.ModalAlert_labelValue__3fQeW {
  font-family: var(--FONT_BOLD);
}

@media (max-width: 600px) {
  .ModalAlert_container_button__1FkVf {
    width: 75% !important;
  }
}

@media (max-width: 500px) {
  .ModalAlert_container_button__1FkVf {
    width: 100% !important;
  }

  .ModalAlert_container_buttons__2i9cY {
    display: block;
  }
}

.TheButton_buttoncheck__1T-ag {
  width: 100%;
  outline: none;
  color: white;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  font-size: 1.2rem;
  min-height: 3.5rem;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  font-family: var(--FONT_BOLD);
  background-color: var(MEDIUM_BLUE);
  box-shadow: 0px 1px 3px 0px rgba(197,190,190,0.75);
}

.TheButton_buttoncheck__1T-ag:hover {
  background-color: var(MEDIUM_BLUE);
}

.modalCodeLogin_principal_container__37xxj {
  height: 100%;
  outline: none;
  display: flex;
  font-size: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.modalCodeLogin_content_modal__Ld7Rq {
  width: 65vw;
  display: flex;
  min-width: 898px;
  max-width: 900px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  padding: 2.5rem 2rem;
  background-color: white;
}
.modalCodeLogin_arrow_icon__1rMPb {
  top: 20px;
  left: 20px;
  display: none;
  font-size: 1.5em;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}
.modalCodeLogin_section_info__tdWbi { 
  width: 50%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
}
.modalCodeLogin_form_login__1Bhz5 { 
  width: 50%;
  padding-left: 40px;
  position: relative;
}
.modalCodeLogin_form__kapFI {
  width: 100%;
}
.modalCodeLogin_login_title__10B7V {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalCodeLogin_subtitle__13jdi {
  color: #333;
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.modalCodeLogin_label__L_lc7 {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalCodeLogin_form__kapFI {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalCodeLogin_input__3sm5A {
  width: 85%;
}
.modalCodeLogin_text_countdown__2wthf {
  color: #333;
  font-size: 0.9em;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 7px;
  font-family: var(--FONT_MEDIUM);
}
.modalCodeLogin_not_code__2IuEr {
  color: #333;
  font-size: 0.9em;
  margin-top: 20px;
  text-align: center;
  margin-bottom: 0px;
  font-family: var(--FONT_MEDIUM);
}
.modalCodeLogin_countdown__3sSkf {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalCodeLogin_request_again__23NAm {
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalCodeLogin_request_again__23NAm:hover {
  cursor: pointer;
}
.modalCodeLogin_close_icon__1tkHZ {
  top: -15px;
  right: -15px;
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
}

@media (max-width: 898px) {
  .modalCodeLogin_content_modal__Ld7Rq {
    min-width: 768px;
  }  
}
@media (max-width: 768px) {  
  .modalCodeLogin_principal_container__37xxj {
    font-size: 15px;
  }  
  .modalCodeLogin_arrow_icon__1rMPb {
    display: flex;
  }
  .modalCodeLogin_content_modal__Ld7Rq {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalCodeLogin_section_info__tdWbi {
    padding: 0;
    height: 25%;
    justify-content: center;
  }
  .modalCodeLogin_form_login__1Bhz5 {
    padding: 0;
    height: 65%;
  }
  .modalCodeLogin_subtitle__13jdi {
    line-height: 20px;
  }

  .modalCodeLogin_close_icon__1tkHZ {
    display: none;
  }
}
@media (max-width: 374px) {
  .modalCodeLogin_form_login__1Bhz5 {
    width: 290px;
  }
}

.TheInput_Container__21Gix {
  min-height: 4.5rem;
  margin-bottom: -1rem;
}

.TheInput_inputcheck__1_CLW {
  width: 100%;
  outline: none;
  text-align: center;
  min-height: 3rem;
  border-radius: 10vh;
  border-style: solid;
  font-family: var(--FONT_LIGHT);
  border-color: rgb(184, 184, 184);
}

.TheInput_error__A8hPQ {
  font-size: 0.85em;
  text-align: center;
  color: var(--COLOR_RED);
  font-family: var(--FONT_MEDIUM);
}

.toast_custom_container_toast__O2ycu {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.toast_custom_icon_toast__1HBD3 {
  font-size: 25px;
  min-width: 27px;
  margin-right: 10px;
  border-radius: 50% !important;
}

.toast_custom_container_toast__O2ycu p {
  font-size: 1em;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}

.ImageInfoLogin_coffe_cup_login__2OEyg {
  width: 35%;
  height: auto;
}

.ImageInfoLogin_title__30zOU {
  font-size: 1em;
  margin-top: 20px;
  text-align: center;
  font-family: var(--FONT_MEDIUM);
}

.ImageInfoLogin_logo_login__a8b-B {
  width: 65%;
  height: auto;
  margin-top: 20px;
}

@media (max-width: 768px){
  .ImageInfoLogin_title__30zOU,
  .ImageInfoLogin_coffe_cup_login__2OEyg {
    display: none;
  }  
  .ImageInfoLogin_logo_login__a8b-B {
    width: 90%;
  }
}
.modalLoginEmail_principal_container__snYel {
  height: 100%;
  outline: none;
  display: flex;
  font-size: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.modalLoginEmail_content_modal__3Wlyi {
  width: 65vw;
  padding: 2rem;
  display: flex;
  min-width: 898px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  background-color: white;
}
.modalLoginEmail_arrow_icon__2p4L3 {
  top: 20px;
  left: 20px;
  display: none;
  font-size: 1.5em;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}
.modalLoginEmail_section_info__1j-em {
  width: 50%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
}
.modalLoginEmail_form_login__3w5zm {
  width: 50%;
  padding-left: 20px;
  position: relative;
}
.modalLoginEmail_login_title__1XMu- {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginEmail_subtitle__1WAdC {
  color: #333;
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.modalLoginEmail_label__17KQW {
  font-size: 1em;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginEmail_form__2L0Nk {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalLoginEmail_input__2YC0c {
  width: 85%;
  text-align: center;
}
.modalLoginEmail_forgot_password__sHjp9 {
  color: #333;
  font-size: 0.9em;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 7px;
  font-family: var(--FONT_MEDIUM);
}
.modalLoginEmail_recover_password__3eGYq {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginEmail_recover_password__3eGYq:hover {
  cursor: pointer;
}
.modalLoginEmail_on_back__DuU-6 {
  color: #333;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  font-family: var(--FONT_BOLD);
}
.modalLoginEmail_on_back__DuU-6:hover {
  cursor: pointer;
}
.modalLoginEmail_label_error__12ZaR {
  font-size: 0.8em;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}

.modalLoginEmail_close_icon__3pIoC {
  top: -15px;
  right: -15px;
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
}

@media (max-width: 898px) {
  .modalLoginEmail_content_modal__3Wlyi {
    min-width: 768px;
  }
}
@media (max-width: 768px) {
  .modalLoginEmail_principal_container__snYel {
    font-size: 15px;
  }
  .modalLoginEmail_arrow_icon__2p4L3 {
    display: flex;
  }
  .modalLoginEmail_content_modal__3Wlyi {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalLoginEmail_section_info__1j-em {
    padding: 0;
    height: 30%;
    justify-content: center;
  }
  .modalLoginEmail_form_login__3w5zm {
    padding: 0;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalLoginEmail_on_back__DuU-6 {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .modalLoginEmail_close_icon__3pIoC {
    display: none;
  }
}
@media (max-width: 374px) {
  .modalLoginEmail_form_login__3w5zm {
    width: 290px;
  }
}

.modalForgotPassword_principal_container__oJ14O {
  height: 100%;
  outline: none;
  display: flex;
  font-size: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.modalForgotPassword_content_modal__1WeaN {
  width: 65vw;
  padding: 2rem;
  display: flex;
  min-width: 898px;  
  max-width: 900px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  background-color: white;
}
.modalForgotPassword_arrow_icon__3iLLK {
  top: 20px;
  left: 20px;
  display: none;
  font-size: 1.5em;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}
.modalForgotPassword_section_info__11ggv { 
  width: 50%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
}
.modalForgotPassword_img_forgot_password__3kYpg {
  width: 50%;  
  height: auto;
}
.modalForgotPassword_form_forgot_password__2HMs0 { 
  width: 50%;
  padding-left: 40px;
  position: relative;
}
.modalForgotPassword_login_title__1gpBp {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalForgotPassword_subtitle__1DOe_ {
  color: #333;
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.modalForgotPassword_label__3ggrt {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalForgotPassword_form__PKHop {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalForgotPassword_on_back__1_Pd3 {
  font-size: 1em;
  margin-top: 20px;
  margin-bottom: 0;
  text-align: center;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalForgotPassword_input__1QGr1 {
  width: 85%;
}
.modalForgotPassword_on_back__1_Pd3:hover {
  cursor: pointer;
}
.modalForgotPassword_label_error__28sc9 {
  font-size: 0.8em;  
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}
.modalForgotPassword_close_icon__3phuf {
  top: -25px;
  right: -15px;
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
}

@media (max-width: 898px) {
  .modalForgotPassword_content_modal__1WeaN {
    min-width: 768px;
  }  
}
@media (max-width: 768px) {  
  .modalForgotPassword_principal_container__oJ14O {
    font-size: 15px;
  }  
  .modalForgotPassword_arrow_icon__3iLLK {
    display: flex;
  }
  .modalForgotPassword_content_modal__1WeaN {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalForgotPassword_section_info__11ggv {
    padding: 0;
    height: 35%;
    justify-content: center;
  }
  .modalForgotPassword_form_forgot_password__2HMs0 {
    padding: 0;
    height: 65%;    
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }    
  .modalForgotPassword_on_back__1_Pd3 {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .modalForgotPassword_close_icon__3phuf {
    display: none;
  }
}
@media (max-width: 374px) {
  .modalForgotPassword_form_forgot_password__2HMs0 {
    width: 290px;
  }
}

.modalLoginCellphone_principal_container__1oJdP {
  height: 100%;
  outline: none;
  display: flex;
  font-size: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.modalLoginCellphone_content_modal__fa3hx {
  width: 65vw;
  display: flex;
  min-width: 898px;
  max-width: 900px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  padding: 2.5rem 2rem;
  background-color: white;
}
.modalLoginCellphone_arrow_icon__3I2YT {
  top: 20px;
  left: 20px;
  display: none;
  font-size: 1.5em;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}
.modalLoginCellphone_section_info__2oHWi {
  width: 50%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
}
.modalLoginCellphone_form_login__wbwct {
  width: 50%;
  padding-left: 40px;
  position: relative;
}
.modalLoginCellphone_login_title__3hMvf {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginCellphone_subtitle__7s_45 {
  color: #333;
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.modalLoginCellphone_label__23xMM {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginCellphone_form__bci3b {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.modalLoginCellphone_input__1l3UJ {
  width: 85%;
}
.modalLoginCellphone_have_password__2obCE {
  color: #333;
  font-size: 0.9em;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 7px;
  font-family: var(--FONT_MEDIUM);
}
.modalLoginCellphone_login_email_and_password__s5D2m {
  font-size: 1em;
  cursor: pointer;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalLoginCellphone_have_password__2obCE:hover,
.modalLoginCellphone_login_email_and_password__s5D2m {
  cursor: pointer;
}
.modalLoginCellphone_close_icon__dkH8e {
  top: -25px;
  right: -15px;
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
}

@media (max-width: 898px) {
  .modalLoginCellphone_content_modal__fa3hx {
    min-width: 768px;
  }
}
@media (max-width: 768px) {
  .modalLoginCellphone_principal_container__1oJdP {
    font-size: 15px;
  }
  .modalLoginCellphone_arrow_icon__3I2YT {
    display: flex;
  }
  .modalLoginCellphone_content_modal__fa3hx {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .modalLoginCellphone_section_info__2oHWi {
    padding: 0;
    height: 25%;
    justify-content: center;
  }
  .modalLoginCellphone_form_login__wbwct {
    padding: 0;
    height: 65%;
  }
  .modalLoginCellphone_footer_info__3qtFu {
    flex-direction: column;
    justify-content: center;
  }
  .modalLoginCellphone_subtitle__7s_45 {
    line-height: 20px;
  }

  .modalLoginCellphone_close_icon__dkH8e {
    display: none;
  }
}
@media (max-width: 374px) {
  .modalLoginCellphone_form_login__wbwct {
    width: 290px;
  }
}

.DropDownButton_container__3Plvl {
  top: 60px;
  z-index: 1;
  right: 20px;
  width: 250px;
  padding: 1rem;
  position: absolute;
  background: white;
  border-radius: 7px;
  border: 1.5px solid var(--COLOR_DISABLED);
}

.DropDownButton_item__33Z-f {
  display: flex;
  cursor: pointer;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  justify-content: space-between;
}

.DropDownButton_text__qduey {
  margin: 0;
  font-family: var(--FONT_MEDIUM);
}

.DropDownButton_icon___sY7U {
  font-size: 1.3rem;
}
.Loader_body__1ixHY {
  text-align: center;
  position: relative;
}

.modal-content {
  border: none;
}

.Loader_principal_container__oo_iY {
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
  width: 115px;
  height: 115px;
  z-index: 9999;
  border-radius: 50%;
  position: relative;
  position: absolute;
  background: var(--DARK_BLUE);
  /* background: white; */
}

.Loader_title__3A5V2 {
  margin: auto;
  bottom: -30px;
  color: white;
  font-size: 1.3em;
  margin-bottom: 0;
  position: absolute;
  font-family: var(--FONT_BOLD);
}

.ButtonHome_container__1yvie {
  width: 45%;
  color: white;
  padding: 20px 0;
  cursor: pointer;
  margin: 0 0 2rem 0;
  text-align: center;
  border-radius: 15px;
  text-decoration: none;
  justify-content: center;
  border: 3px solid white;
}

.ButtonHome_container__1yvie:hover {
  color: white;
}

.ButtonHome_icon__10tKV {
  font-size: 2.7rem;
}

.ButtonHome_text__1Wn1_ {
  margin: 0;
  font-family: var(--FONT_BOLD);
}

@media (max-width: 750px ) {
  .ButtonHome_container__1yvie {
    width: 48%;
  }
}
.ViewDefault_view__1DhyJ {
  text-align: center;
}

.ViewDefault_container_image__3yiH7 {
  width: 200px;
  margin: auto;
}

.ViewDefault_container_image__3yiH7 > img {
  width: 100%;
}

.ViewDefault_text__3tZ8T {
  margin: 15px 0;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ViewDefault_text_intro__1Cpw_ {
  margin: 15px 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.ViewDefault_container_button__3WlIP {
  width: 50%;
  margin: auto;
}

@media (max-width: 600px){
  .ViewDefault_container_button__3WlIP {
    width: 80%;
  }
}

@media (max-width: 400px){
  .ViewDefault_container_button__3WlIP {
    width: 90%;
  }
}
.LoanInformation_container_info__2Pmfb {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 20px;
  justify-content: center;

}
.ItemToCreditActive_info_complete__1XIlc {
  margin: 25px 0;
  width: calc(100% / 3);
}

.ItemToCreditActive_label__2qD6n {
  margin-bottom: .2rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.ItemToCreditActive_value__6a9x3 {
  font-size: 1.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 570px ) {
  .ItemToCreditActive_info_complete__1XIlc {
    width: 50%;
  }
}

@media (max-width: 570px ) {
  .ItemToCreditActive_value__6a9x3 {
    font-size: 1.1rem;
  }
}
.ModalPayCredit_modal_body__2WkS_ {
  padding: 2rem;
  position: relative;
}

.ModalPayCredit_body__1Oe5V {
  text-align: center;
}

.ModalPayCredit_container_image__2CR2e {
  margin-bottom: 1rem;
  height: 70px;
}

.ModalPayCredit_image_logo__2iSPV {
  height: 100%;
}

.ModalPayCredit_modal_title__3HgK7 {
  margin: 1rem 0;
  font-size: 1.2rem;
  text-align: center;
  font-family: var(--FONT_BOLD);
}

.ModalPayCredit_text_info__NHSku {
  margin: 1rem 0;
  font-size: 1.1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.ModalPayCredit_label__1-p3I {
  margin: 1rem 0 0 0;
}

.ModalPayCredit_inputToPay__3OZ3- {
  width: 50%;
  outline: none;
  padding: 7px 15px;
  text-align: center;
  margin-bottom: 1rem;
  border-radius: 20px;
  border: 1px solid #999;
}

.ModalPayCredit_container_button__1NaXo {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

.ModalPayCredit_infoCredit__zBjI9 {
  display: flex;
  justify-content: space-evenly;
}

.ModalPayCredit_labelTitle__2jX_j {
  cursor: pointer;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

.ModalPayCredit_labelTitle__2jX_j:hover {
  text-decoration: underline;
}

.ModalPayCredit_labelValue__1bW6N {
  font-family: var(--FONT_BOLD);
}

@media (max-width: 600px) {
  .ModalPayCredit_container_button__1NaXo {
    width: 75% !important;
  }
}

@media (max-width: 500px) {
  .ModalPayCredit_container_button__1NaXo {
    width: 100% !important;
  }
}

.ModalRetrenchment_modal_body__1KxRI {
  padding: 2rem;
  position: relative;
}

.ModalRetrenchment_button_close__1B59e {
  top: 20px;
  right: 20px;
  cursor: pointer;
  position: absolute;
  font-size: calc(1rem + 1vw);
}

.ModalRetrenchment_modal_title__3Ke_J {
  margin: 1rem 0;
  text-align: center;
  font-size: calc(1rem + 1vw);
  font-family: var(--FONT_BOLD);
}

.ModalRetrenchment_container_image__3SLP6 {
  margin-bottom: 1rem;
}

.ModalRetrenchment_body__1U5cf {
  text-align: center;
}

.ModalRetrenchment_text_blue__3KlTY {
  margin: 0;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalRetrenchment_text_info__1efod {
  margin: 1rem 0;
  font-size: .9rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.ModalRetrenchment_important__29dvg {
  font-family: var(--FONT_BOLD);
}

.ModalRetrenchment_container_button__3X27u {
  width: 60%;
  margin: auto;
  margin-top: 1rem;
}

@media (max-width: 600px) {
  .ModalRetrenchment_container_button__3X27u {
    width: 75%;
  } 
}

@media (max-width: 500px) {
  .ModalRetrenchment_container_button__3X27u {
    width: 85%;
  } 
}

@media (max-width: 350px) {
  .ModalRetrenchment_container_button__3X27u {
    width: 95%;
  } 
}
.ModalCreditRequest_title__laCOu {
  margin: 0;
  display: flex;
  justify-content: center;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalCreditRequest_body__1cBEJ {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.ModalCreditRequest_close__lp_IP {
  top: 10px;
  right: 15px;
  font-size: 1.3rem;
  position: absolute;
  color: VAR(--COLOR_TEXT);
}

.ModalCreditRequest_simulator__203b7 {
  width: 50%;
  padding: 1rem;
  text-align: center;
}

.ModalCreditRequest_resume__3ESWD {
  width: 50%;
}

@media (max-width: 991px) {
  .ModalCreditRequest_body__1cBEJ {
    display: block;
  }

  .ModalCreditRequest_resume__3ESWD,
  .ModalCreditRequest_simulator__203b7 {
    width: 80%;
    margin: auto;
    padding: 1rem 0;
  }

  .ModalCreditRequest_resume__3ESWD {
    margin-bottom: 1rem;
  }

  .ModalCreditRequest_container_button__3MV66 {
    left: 13%;
    right: 13%;
    bottom: 20px;
    position: absolute;
  }
}

@media (max-width: 469px) {
  .ModalCreditRequest_resume__3ESWD,
  .ModalCreditRequest_simulator__203b7 {
    width: 100%;
  }
}

.Resume_biweekly_fee__1WHpk {
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  font-size: 25px;
}

.Resume_biweekly_fee_responsive__teeKw {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.Resume_Container__19Zrv {
  color: #747171;
  border-radius: 15px;
  background: #e2d9d93f;
  border: 3px solid white;
  padding: 15px 10px 10px 10px;
  margin: 0.5rem auto 2rem auto;
}

.Resume_container_15__3xPgf {
  border-radius: 15px;
  background: #ffffff8a;
  border: 1px solid white;
  padding: 10px 10px 5px 10px;
  margin: 0.5rem auto 2rem auto;
}

.Resume_Container__19Zrv > * {
  display: flex;
  line-height: 28px;
  align-items: center;
  justify-content: space-between;
  font-family: var(--FONT_BOLD);
}

.Resume_Container__19Zrv > div > h5 {
  font-size: 1.5rem;
  margin-top: 0.2rem;
  margin-bottom: 0.5rem;
}

.Resume_Container__19Zrv > div > p {
  margin: 0;
  font-family: var(--FONT_BOLD);
  /* font-family: var(--FONT_LIGHT); */
}

.Resume_title__2M1ks {
  margin: 0;
  display: flex;
  font-size: 1.3rem;
  justify-content: center;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Resume_tooltip__10rFU {
  width: 200px;
  line-height: .9rem;
  font-family: var(--FONT_MEDIUM);
}

.Resume_icon__2QHpP {
  outline: none;
}

@media (max-width: 600px) {
  .Resume_Container__19Zrv > div > p {
    font-size: 0.9rem;
  }

  .Resume_Container__19Zrv > div > h5 {
    font-size: 1.2rem;
  }
}

@media (max-width: 600px) {
  .Resume_Container__19Zrv > div > p {
    font-size: 0.8rem;
  }

  .Resume_Container__19Zrv > div > h5 {
    font-size: 1.1rem;
  }
}

.Slider_SliderL__3g6C_ {
  margin-bottom: 1rem;
  background-color: white;
  padding: 0.35rem !important;
  border-radius: 10vh !important;
  /* color: var(--MEDIUM_BLUE) !important; */
  color: var(--MEDIUM_BLUE) !important;
}

.Slider_textModal__1nXZQ,
.Slider_text__1_7gn {
  font-size: 0.9rem;
  margin: 0 0 0.5rem 0;
  font-family: var(--FONT_BOLD);
  color: var(--COLOR_TEXT) !important;
}

.Slider_TextSliderModal__3UpIx,
.Slider_TextSlider__8OWjU {
  color: var(--LIGHT_BLUE) !important;
  font-family: var(--FONT_BOLD) !important;
}

.Slider_marksModeModal__2TPjt,
.Slider_marksMode__oDWB5{
  width: 99%;
  display: flex;
  margin-left: 1%;
  margin-top: -0.4rem;
  color: var(--FONT_BOLD);
  justify-content: space-between;
}

.Slider_marksMode2__3HZ-d{
  width: 100%;
  display: flex;
  margin-top: -0.4rem;
  color: var(--COLOR_TEXT) !important;
  justify-content: space-between;
}
.Slider_marksMode1__2rpzJ{
  width: 100%;
  display: flex;
  color: var(--COLOR_TEXT) !important;
  margin-left: -10%;
  margin-top: -0.4rem;
  justify-content: space-between;
}

@media (max-width: 600px) {
  .LandingSliders_slider_money__3u8QY {
    width: 80%;
    margin: 1rem auto 2rem auto;
  }
}
.modalDetailCredit_principal_content__J0_KY .modal-content {
  border-radius: 13px;
  background-color: white !important;
}
.modalDetailCredit_principal_content__J0_KY .modal-body {
  padding: 20px 20px 30px;
} 
.modalDetailCredit_close_icon__1G5K- {
  top: 15px;
  right: 15px;
  font-size: 30px;
  cursor: pointer;
  position: absolute;
  color: var(--COLOR_TEXT);
}
.modalDetailCredit_principal_container__2Lp_6 {
  display: flex;
  font-size: 16px;
  align-items: center;
  flex-direction: column;
}
.modalDetailCredit_title__3j_78 {
  font-size: 1.3em;
  overflow: hidden;
  margin-bottom: 0px;
  white-space: nowrap;
  color: var(--DARK_BLUE);
  text-overflow: ellipsis;
  /* width: calc(100% - 80px); */
  font-family: var(--FONT_BOLD);
}
.modalDetailCredit_paid__2vtnH {
  font-size: 1em;
  font-weight: bold;
  font-family: var(--FONT_MEDIUM);
}
/* installments */
.modalDetailCredit_container_installments__1xNCA {
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 20px;
}
.modalDetailCredit_container_row__23v1z {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.modalDetailCredit_installments_title_table__3Rv0W {
  font-size: 0.9em;
  text-align: center;
  width: calc(100% / 6);
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}
.modalDetailCredit_installments_value_table__3nFmJ {
  width: calc(100% / 6);
  text-align: center;
}
.modalDetailCredit_value__2ekUl {
  font-size: 1em;
  font-family: var(--FONT_MEDIUM);
}

.modalDetailCredit_valuesTotal__3-Z1V {
  width: 100%;
  text-align: end;
}

.modalDetailCredit_totalToPay__1v2ny{
  margin: 0;
  font-size: 0.9rem;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

.modalDetailCredit_grandTotal__14pIK>span,
.modalDetailCredit_totalToPay__1v2ny>span{
  font-size: 1.1em;
  color: var(--COLOR_DANGER);
  font-family: var(--FONT_MEDIUM);
}

.modalDetailCredit_grandTotal__14pIK {
  margin: 0;
  font-size: 1.1rem;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 991px) {
  .modalDetailCredit_container_installments__1xNCA {
    width: 100%;
    overflow-x: scroll;
  }

  .modalDetailCredit_container_row__23v1z {
    width: 700px;
  }
}

@media (max-width: 768px) {
  .modalDetailCredit_container_btn__36b9X { width: 80%; }
  .modalDetailCredit_container_state__1BK8K { width: 80%; }
  .modalDetailCredit_principal_container__2Lp_6 { font-size: 15px; }
  .modalDetailCredit_dates__FH8kt, .modalDetailCredit_installments_date__K0l5O { width: 60%; }
  .modalDetailCredit_installments__2iTwG, .modalDetailCredit_installments_value__2B3Tg { width: 40%; }
}
.Landing_LandingStyle__67rbs {
  min-height: 100vh;
  position: relative;
  background-size: cover;
  background-repeat: no-repeat;
  /* background-image: url("../../images/invertida.jpeg"); */
}

.Landing_mainCenter__2DkEd {
  display: grid;
  text-align: center;
  align-content: center;
  height: calc(100vh - 75px);
}

.Landing_main__3dJ08 {
  padding: 2vh;
  display: flex;
  text-align: center;
  justify-content: space-evenly;
}

.Landing_containerSICLogo__2wIi7 {
  left: 0;
  bottom: 0;
  padding: 0px 20px;
  position: absolute;
  background: #e2d9d93f;
  border-radius: 0 25px 0 0;
  min-height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Landing_containerTheFirm__2ads0 {
  bottom: 0;
  left:  calc(50vw - 110px);
  padding: 10px 20px;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Landing_textTheFirm__3NbLl {
  font-size: 1.1rem;
  font-weight: 900;
  font-family: auto;
  color: rgb(195, 5, 5);
}

.Landing_containerSICLogo__2wIi7 > img {
  width: 200px;
}

.Landing_Container__3slpT {
  width: 40%;
  flex-direction: column;
  justify-content: center;
}

.Landing_responsiveDatacredito__1OIWV {
  width: 200px;
  margin: auto;
}

.Landing_resume__wLPxk {
  width:30%;
}

.Landing_container_slider_resume__tjiUp {
  width: 50%;
  margin: auto;
}

@media (max-width: 1100px) {
  .Landing_Container__3slpT {
    width: 50%;
  }

  .Landing_resume__wLPxk {
    width:32%;
  }
}

@media (max-width: 990px) {
  .Landing_main__3dJ08 {
    padding: 1rem;
    margin-top: 2rem;
    display: block;
    text-align: center;
    justify-content: space-evenly;
  }

  .Landing_resume__wLPxk,
  .Landing_Container__3slpT {
    width: 70%;
    margin: auto;
  }
}

@media (max-width: 990px) {
  .Landing_mainCenter__2DkEd {
    height: auto;
    display: block;
    margin-top: 2rem;
  }

  .Landing_containerSICLogo__2wIi7 {
    border-radius: 0;
    position: initial;
    margin-top: 1.5rem;
    text-align: center;
    padding: 10px 20px 0 10px;
  }

  .Landing_containerTheFirm__2ads0 {
    padding: 20px 20px;
    position: initial;
  }
  
  .Landing_containerSICLogo__2wIi7 > img {
    width: 200px;
  }

  .Landing_containerLogoDatacreditoResponsive__kxMsd {
    display: block;
  }
}

@media (max-width: 800px) {
  .Landing_LandingStyle__67rbs {
    min-height: 100vh;
    background-size: cover;
    background-repeat: no-repeat;
    /* background-image: url("../../images/Landing/Banner_Responsive.jpg"); */
  }
}

@media (max-width: 600px) {
  .Landing_resume__wLPxk,
  .Landing_Container__3slpT {
    width: 95%;
  }

  .Landing_LandingStyle__67rbs {
    background-position-x: 20%;
  }
  
}

@media (max-width: 400px) {
  .Landing_main__3dJ08 {
    padding: 10px;
  }
  .Landing_Container__3slpT {
    width: 100%;
  }
}
.Title_title__N4XgR {
  font-size: 1.5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.Title_text__5SRUF {
  margin-bottom: 1rem;
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 800px) {
  .Title_title__N4XgR {
    color: var(--MEDIUM_BLUE);
    font-size: 1.3rem;
  }
  
  .Title_text__5SRUF {
    color: var(--MEDIUM_BLUE);
  }
}

.ModalLanding_alignModal__2M7PX {
  background-color: transparent !important;
}

.ModalLanding_modalContainer__zzzPo {
  padding: 2rem;
  text-align: center;
  background-color: white;
  border-radius: 20px;
}

.ModalLanding_modalIMG__3Yd6c {
  width: 35%;
}

.ModalLanding_colorText__1Cz92 {
  font-size: 1.2rem;
  margin: 2rem 0 1rem 0;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalLanding_pText__2Ua7e {
  font-size: 0.9rem;
  line-height: 24px;
  text-align: center;
  color: var(--COLOR_TEXT);
}

.ModalLanding_container_submit__2SvsN {
  width: 70%;
  margin: auto;
}

.ModalLanding_colorTextPhone__2yrUO {
  font-size: 1rem;
  margin-bottom: 0.5rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalLanding_colorTextVerificacion2__2cSmL {
  font-size: 1rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalLanding_CrediConvenioText__3LvZ9 {
  font-size: 1rem;
  margin-left: 0.3rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalLanding_textContainerCrediConvenio__10Rmi {
  display: flex;
  justify-content: center;
}

.ModalLanding_MessageAgain__3wzD8 {
  display: flex;
  align-items: center;
  flex-direction: column;
}

.ModalLanding_arribaSolicitar__25YcZ {
  margin-top: 1rem;
  margin-bottom: 0;
  font-size: 0.8rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.ModalLanding_SolicitarNuevamente__2y3qE {
  font-size: 1rem;
  cursor: pointer;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalLanding_containerImages__30piZ {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-evenly;
}

.ModalLanding_modalImageWarn__3d1CO {
  width: 200px;
}

.ModalLanding_pTextWarn__3ywsa {
  line-height: 1.4;
  font-size: 1.5rem;
  text-align: center;
  margin-bottom: 2rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1200px) {
  .ModalLanding_modalImageWarn__3d1CO {
    width: 20%;
  }

  .ModalLanding_modalImageDataCredit__1ylV2 {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .ModalLanding_pTextWarn__3ywsa {
    font-size: 1rem;
  }
}

@media (max-width: 600px) {
  .ModalLanding_pTextWarn__3ywsa {
    font-size: 1.2rem;
  }
}

@media (max-width: 575px) {
  .ModalLanding_modalContainer__zzzPo {
    padding: 2rem 1rem;
  }

  .ModalLanding_container_submit__2SvsN {
    width: 100%;
  }
}
.notFound_principal_container__5ZEda {
  width: 100vw;
  height: 100vh;
  display: flex;
  padding: 0 20px;
  font-size: 16px;
  align-items: center;
  flex-direction: column;
  background-color: #fff;
}
.notFound_header__2Miaq {
  height: 20%;
  width: 100%;
  display: flex;
  padding: 20px 0;
  justify-content: center;
}
.notFound_content__VbSoA {
  height: 80%;
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}
.notFound_logo__3U6Et {
  width: 40%;
  object-fit: contain;
}
.notFound_not_found__2o5Cd {
  height: 60%;
  margin-bottom: 20px;
  object-fit: contain;
}
.notFound_title__1E5Qi {
  color: var(--MEDIUM_BLUE);
  font-size: 1.4em;
  line-height: 20px;
  text-align: center;
  font-family: var(--FONT_BOLD);
}
.notFound_subtitle__2pO8q {
  color: var(--MEDIUM_BLUE);
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.notFound_text__td_dI {
  color: var(--MEDIUM_BLUE);
  font-size: 1em;
  text-align: center;
  font-family: var(--FONT_MEDIUM);
}
.notFound_btn__xkuMD {
  width: 511px;
  outline: none;
  color: white;
  cursor: pointer;
  font-size: 1.2em;
  min-height: 3rem;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  border: 3px solid white;
  background-color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  box-shadow: 0px 1px 3px 0px rgba(197,190,190,0.75);
}
.notFound_btn__xkuMD:hover {
  background-color: var(MEDIUM_BLUE);
}
@media (max-width: 768px) {
  .notFound_principal_container__5ZEda { font-size: 15px; }
  .notFound_content__VbSoA { height: 85%; }
}
@media (max-width: 600px) {
  .notFound_btn__xkuMD { width: 90%; }
}
@media (max-width: 425px) {
  .notFound_not_found__2o5Cd {
    width: 100%;
    height: 50%;
  }
  .notFound_btn__xkuMD { width: 100%; }
}
.History_principal_container__2X8yR {
  font-size: 16px;
  overflow-y: auto;
  margin-top: 2rem;
  padding: 0 20px 20px 20px;
  height: calc(100vh - 90px);
}
/* history cards */
.History_container_cards__3xs9V {
  display: flex;
  flex-wrap: wrap;  
}
.History_card_history__2_Ghp {
  width: 18.5%;
  display: flex;
  padding: 15px;
  border-radius: 7px;
  margin: 0 20px 20px 0;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  border: 2px solid #d9d9d9;
}
.History_info_card__24uFA {
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.History_title_card__1NTld {
  width: 100%;
  color: #333;
  font-size: 1rem;
  overflow: hidden;
  margin-bottom: 30px;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-family: var(--FONT_MEDIUM);
}
.History_container_value__2fjAN {
  display: flex;
  margin-bottom: 5px;
}
.History_label__3dGPL {
  font-size: 1rem;
  color: #015c83;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}
.History_paid__3Cxah {
  font-size: 1rem;
  margin-left: 7px;
  color: #4df591;
  margin-bottom: 0;
  font-family: var(--FONT_MEDIUM);
}
.History_value__1d38D {
  color: #333;
  font-size: 1rem;
  margin-left: 7px;
  margin-bottom: 0;
  font-family: var(--FONT_MEDIUM);
}
.History_right_chevron_icon__2LviZ {
  font-size: 1.7em;
  color: #015c83;
}
.History_right_chevron_icon__2LviZ:hover {
  cursor: pointer;
}

.History_containerDefault__2rRaG {
  width: 100%;
  display: grid;
  height: calc(100% - 48px);
  grid-template-columns: repeat(3, 1fr);
}

.History_containerDefault__2rRaG > img {
  width: 80%;
  grid-row: 1;
  margin: auto;
  grid-column: 2 / 3;
}

/* response */
@media(max-width: 1352px) {
  .History_card_history__2_Ghp { width: 18%; }
}
@media(max-width: 1200px) {
  .History_card_history__2_Ghp { width: 23%; }
}
@media(max-width: 1039px) {
  .History_card_history__2_Ghp { width: 31%; }
}
@media(max-width: 896px) {
  .History_card_history__2_Ghp { width: 47%; }
}
@media(max-width: 706px) {
  .History_card_history__2_Ghp { width: 46%; }
}
@media(max-width:539px) {
  .History_card_history__2_Ghp { width: 100%; margin-right: 0; }
  .History_title_card__1NTld { margin-bottom: 17px; }
}
.title_container_title__2hLpt {
  display: flex;
  font-size: 16px;
  cursor: pointer;
  align-items: center;  
  padding-bottom: 20px;
}
.title_title__bPTEl {
  font-size: 1.2em;
  color: #015c83;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}
.title_icon__3aapK {
  font-size: 1.7em;
  color: #015c83;
  margin-right: 15px;
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 768px) {
  .title_container_title__2hLpt { justify-content: center; }
}
.ForgotPassword_container_recover_password__vi-lq {
  display: grid;
  height: 100vh;
  overflow: auto;
  align-content: center;
  justify-content: center;
}

.ForgotPassword_form_passwords__14EEY {
  width: 40vw;
  margin: auto;
  text-align: center;
}

.ForgotPassword_icon_password__23BO2 {
  width: 200px;
}

.ForgotPassword_form_passwords__14EEY > h3 {
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ForgotPassword_developer_logo__DSkTA {
  width: 200px;
  right: 2rem;
  bottom: 2rem;
  position: absolute;
}

.ForgotPassword_container_input__38U3i {
  text-align: center;
  position: relative;
  margin-bottom: 2rem;
}

.ForgotPassword_label_error__1NbJL {
  width: 100%;
  bottom: -2.1rem;
  font-size: 0.9em;
  position: absolute;
  margin-top: -0.9rem;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}

@media (max-width: 1024px) {
  .ForgotPassword_form_passwords__14EEY {
    width: 50vw;
  }
}

@media (max-width: 768px) {
  .ForgotPassword_form_passwords__14EEY {
    width: 60vw;
  }
}

@media (max-width: 425px) {
  .ForgotPassword_form_passwords__14EEY {
    width: 90vw;
  }
}

.Credit_Approved_Container__2b9ot {
  min-height: 100vh;
}

.Credit_Approved_container_info_credit__3bRi7 {
  width: 50vw;
  margin: auto;
  text-align: center;

}

@media (max-width: 990px) {
  .Credit_Approved_container_info_credit__3bRi7 {
    width: 80vw;
  } 
}

@media (max-width: 630px) {
  .Credit_Approved_container_info_credit__3bRi7 {
    width: 90vw;
  }
}
.InfoCode_Container__3-DhE {
  padding-top: 2rem;
}

.InfoCode_introText__eifIa {
  margin: 0;
  font-size: 0.9rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.InfoCode_label__1tqVZ {
  font-size: 0.9rem;
  margin-bottom: 0.5rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.InfoCode_Divs__3_DYl {
  max-width: 50%;
  margin-bottom: 1.3rem;
 
}

.InfoCode_smallLeter__3UaHs {
  font-size: 0.7rem;
  margin-top: -0.7rem;
}

.InfoCode_ContainerInput__1fUPb {
  width: 40%;
  margin: .5rem auto 1rem auto;
}

.InfoCode_iconChecked__3LgYy,
.InfoCode_icon__1NyRF {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.InfoCode_iconChecked__3LgYy {
  color: var(--MEDIUM_BLUE);
}

.InfoCode_icon__1NyRF {
  color: #989696;
}

.InfoCode_container_check__4aza- {
  margin: 0.3rem 0;
  font-family: var(--FONT_LIGHT);
  margin-bottom: 1.5rem;
}

.InfoCode_link__1j23b,
.InfoCode_link__1j23b:hover {
  text-decoration: none;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.InfoCode_countdown__3bq9q {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.InfoCode_request_again__3Qyea {
  font-size: 1.1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.InfoCode_request_again__3Qyea:hover {
  cursor: pointer;
}

@media (max-width: 630px) {
  .InfoCode_ContainerInput__1fUPb {
    width: 60%;
  } 
}

@media (max-width: 500px) {
  .InfoCode_ContainerInput__1fUPb {
    width: 80%;
  }

  .InfoCode_container_check__4aza- {
    width: 100%;
    margin: .7rem 0;
    text-align: left;
  }
}

@media (max-width: 400px) {
  .InfoCode_ContainerInput__1fUPb {
    width: 90%;
  }
}

.ArrowBack_back__2wYZn {
  left: 2%;
  display: flex;
  margin: 1rem 0;
  cursor: pointer;
  padding: 7.5px 0;
  position: absolute;
  align-items: center;
  color: var(--MEDIUM_BLUE);
}

.ArrowBack_Logo__gX9Ol {
  height: 55px;
  top: 30px;
  right: 30px;
  position: absolute;
}

.ArrowBack_textBack__19g5x {
  margin: 0;
  font-family: var(--FONT_BOLD);
}

@media (max-width: 768px) {
  .ArrowBack_textBack__19g5x {
    display: none;
  }
  .ArrowBack_logoContainer__1Kbhl {
    display: flex;
    justify-content: center;
  }
  .ArrowBack_Logo__gX9Ol {
    position: relative;
    margin-top: 12px;
    top: 0px;
    right: 0px;
  }
}

.InfoCredito_InfoContainer__3wTEU {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.InfoCredito_info__cn-2N {
  margin: 0;
  text-align: center;
  width: calc(100% / 4)
}

.InfoCredito_label__wB0zi {
  margin: 0;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.InfoCredito_value__20XJx {
  margin: 0;
  font-size: 1.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.InfoCredito_remaining_value__1D_l-{
  margin: 0;
  font-size: 1.3rem;
  font-family: var(--FONT_BOLD);
}

.InfoCredito_disburse_value__2raQq{
  margin: 0;
  font-size: 1.3rem;
  color: var(--COLOR_SUCCESS);
  font-family: var(--FONT_BOLD);
}

.InfoCredito_MontoYdesembolso__3Kpgz{
  display: flex;
  justify-content: space-between;
}
.InfoCredito_MontoYdesembolso__3Kpgz>*{
  margin-top: 1.6rem;
  width: 15rem;

  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {  
  .InfoCredito_info__cn-2N {
    width: 50%;
    margin-bottom: 2rem;
  }  
  .InfoCredito_MontoYdesembolso__3Kpgz>*{
    margin-top: 0.8rem;
    width: 15rem;
  
    display: flex;
    flex-direction: column;
  }
}

@media (max-width: 745px) {  
  .InfoCredito_InfoContainer__3wTEU {
    width: 100%;
  }  
}
.ImageAndText_container__ckcwB {
  padding: 4rem 0 0 0;
  text-align: center;
  margin-bottom: 2.5rem;
}

.ImageAndText_TextColor__2QCIp {
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ImageAndText_simpleStyle__3NhZu {
  font-size: 1rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}


@media (max-width: 600px){
  .ImageAndText_TextColor__2QCIp{
    font-size: 1rem;
    padding: 0.5rem;
  }

  .ImageAndText_img__eC2Ti{
    width: 8rem;
  }
  
}

.Credit_Disbursement_container_info_credit__KfWVC {
  width: 50%;
  margin: auto;
}

.Credit_Disbursement_content__ZTui- {
  width: 30%;
  margin: 2.5rem auto;
}

@media (max-width: 1250px) {
  .Credit_Disbursement_content__ZTui- {
    width: 40%;
  }
}

@media (max-width: 992px) {
  .Credit_Disbursement_content__ZTui- {
    width: 50%;
  }
}

@media (max-width: 768px) {
  .Credit_Disbursement_content__ZTui- {
    width: 65%;
  }
}

@media (max-width: 550px) {
  .Credit_Disbursement_content__ZTui- {
    width: 75%;
  }
  .Credit_Disbursement_container_info_credit__KfWVC {
    width: 75%;
  }
}

@media (max-width: 450px) {
  .Credit_Disbursement_content__ZTui- {
    width: 95%;
  }
  .Credit_Disbursement_container_info_credit__KfWVC {
    width: 95%;
  }
}

.infodisbur_Container__2Cdms {
  width: 100%;
  text-align: center;
  margin-bottom: 1.5rem;
}

.infodisbur_logodisbur__2k7jY {
  width: 8rem;
  margin-bottom: 1.5rem;
  margin-top: 1rem;
}

.infodisbur_title__1F42h {
  font-size: 1.4rem;
  text-align: center;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.infodisbur_subtitle__8lnvT {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 600px) {
  .infodisbur_Container__2Cdms {
    width: 100%;
    margin: 0px;
    margin-bottom: 0px;
  }

  .infodisbur_title__1F42h {
    font-size: 1rem;
  }
  .infodisbur_subtitle__8lnvT {
    font-size: 70%;
  }
}

.logoDisbur_logoCrediConvenio__23KE1 {
  height: 65px;
  padding: 5px;
}

.logoDisbur_logo__2acHm {
  height: 100%;
}

@media (max-width: 768px){
  .logoDisbur_logoCrediConvenio__23KE1 {
    text-align: center;
  }
}
.TabPanelBank_containerTabComplete__2Ic9P {
  margin: 1rem 0;
  text-align: center;
}

.TabPanelBank_title__1opQZ {
  font-family: var(--FONT_BOLD);
}

.TabPanelBank_containerControlTab__2KrPe {
  display: flex;
  align-items: center;
  font-family: var(--FONT_BOLD);
  justify-content: space-between;
}

.TabPanelBank_tabActive__3v0eR {
  background-color: blue;
  height: 3.8rem;
  padding: 1.1rem 0;
  width: 50%;
  color: white;
  cursor: pointer;
  text-align: center;
  border: 2px solid white;
  font-family: var(--FONT_BOLD);
  background: var(--MEDIUM_BLUE);
}

.TabPanelBank_tabInactive__1jNuc {
  width: 50%;
  height: 3.8rem;
  padding: 1.1rem 0;
  cursor: pointer;
  text-align: center;
  background: white;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  border: 2px solid var(--DARK_BLUE);
}

.TabPanelBank_containerInput__1gY9Y {
  text-align: center;
  margin-bottom: 2rem;
}

.TabPanelBank_containerInput__1gY9Y > label {
  width: 100%;
  margin: 2rem 0 1rem 0;

}

.TabPanelBank_containerInput__1gY9Y > div {
  width: 100%;
  margin-top: 1rem;
}

.TabPanelBank_note__2l-ZB {
  color: var(--COLOR_RED);
}
.TheSelect_custom_select__2qe4j {  
  position: relative;
  min-height: 4.5rem;
  margin-bottom: -1rem;
}

.TheSelect_btn_select__dzA_C {  
  width: 100% !important;
  color: #333 !important;
  display: flex !important;
  outline: none !important;
  font-size: 1em !important;
  padding: 0 20px !important;
  min-height: 3rem !important;
  max-height: 3rem !important;
  line-height: 17px !important;
  text-align: center !important;
  border-radius: 10vh !important;
  text-transform: none !important;
  background-color: #fff !important;
  justify-content: space-between !important;
  font-family: var(--FONT_LIGHT) !important;
  border: 2px solid rgb(184, 184, 184) !important;
}

.TheSelect_btn_select__dzA_C .MuiButton-label {
  justify-content: space-between !important;
}

.TheSelect_icon_select__1vNFh {
  color: #999 !important;
  font-size: 16px !important;
}

.TheSelect_options_select__D-PLD {
  z-index: 1;
  width: 330px;
  height: 150px;
  bottom: -135px;
  overflow-y: auto;
  padding: 8px 12px;
  position: absolute;
  border-radius: 7px;
  background-color: #fff;  
}

.TheSelect_default_text__1vkOw {
  color: #666;
  margin-bottom: 0;
  padding-top: 63px;
  text-align: center;
  font-family: VAR(--FONT_LIGHT);
}

.TheSelect_option_select__39Axu {
  color: #666;
  margin-bottom: 0;
  padding-bottom: 5px;
  font-family: VAR(--FONT_LIGHT);
}

.TheSelect_option_select__39Axu:hover {
  cursor: pointer;
}

.TheSelect_container_selected_options__1nS1e {
  width: 100%;
  height: auto;
  flex-wrap: wrap;
  display: flex;
  margin-top: 15px;
}

.TheSelect_item_selected_option__2OGLj {
  display: flex;
  max-height: 29px;
  position: relative;
  margin-right: 10px;  
  margin-bottom: 10px;
  border-radius: 20px;
  align-items: center;
  padding: 5px 30px 5px 12px;
  background-color: VAR(--COLOR_PURPLE);
}

.TheSelect_option_name__3rkc_ {
  width: 100%;
  color: #fff;
  font-size: 0.8em;
  margin-bottom: 0;  
  font-family: VAR(--FONT_BOLD);
}

.TheSelect_error__12Y_3 {
  color: red;
  font-weight: bold;
  text-align: center;
}
.ModalValidate_alignModal__7eQvZ {
  height: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.ModalValidate_modalContainer__1ptPQ {
  width: 40%;
  padding: 2rem;
  display: flex;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.ModalValidate_modalIMG__2qR2W {
  width: 25%;
  min-width: 110px;
}
.ModalValidate_colorGreen__3VU4g {
  margin-top: 2rem;
  font-size: 1.5em;
  color: var(--COLOR_SUCCESS);
  font-family: var(--FONT_BOLD);
}
.ModalValidate_text__18ivJ {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}
.ModalValidate_container_button__2ngVu {
  width: 60%;
}

@media (max-width: 1111px) {
  .ModalValidate_modalContainer__1ptPQ {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .ModalValidate_modalContainer__1ptPQ {
    width: 70%;
  }
}

@media (max-width: 478px) {
  .ModalValidate_modalContainer__1ptPQ {
    width: 80%;
    padding: 1rem;
  }

  .ModalValidate_container_button__2ngVu {
    width: 95%;
  }
}

@media (max-width: 400px) {
  .ModalValidate_modalContainer__1ptPQ {
    width: 95%;
  }

  .ModalValidate_container_button__2ngVu {
    width: 100%;
  }
}
.ConfirmCreditToUser_main__2XGZU {
  width: 70vw;
  margin: auto;
  display: flex;
  height: 100vh;
  text-align: center;
  align-items: center;
  align-content: center;
  flex-direction: column;
  justify-content: center;
}

.ConfirmCreditToUser_title__23rKV {
  /* color: var(--DARK_BLUE); */
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.ConfirmCreditToUser_creditInfo__3m6Ok {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.ConfirmCreditToUser_creditInfo__3m6Ok p{
  width: 200px;
  text-align: center;
  color: var(--DARK_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.ConfirmCreditToUser_creditInfo__3m6Ok p span{
  font-family: var(--FONT_BOLD);
  color: var(--COLOR_SUCCESS);
}

.ConfirmCreditToUser_image__9Nbcl {
  width: 170px;
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.ConfirmCreditToUser_container_buttons__3PSfX {
  width: 70%;
  display: flex;
  justify-content: space-between;
}

.ConfirmCreditToUser_container_buttons__3PSfX button {
  width: 48%;
}

.ConfirmCreditToUser_container__1R39f :first-child:hover {
  width: 500px !important;
}

@media (max-width: 768px) {
  .ConfirmCreditToUser_main__2XGZU {
    width: 80vw;
  }
}

@media (max-width: 600px) {
  .ConfirmCreditToUser_main__2XGZU {
    width: 90vw;
  }
}

@media (max-width: 500px) {
  .ConfirmCreditToUser_main__2XGZU {
    width: 95vw;
  }

  .ConfirmCreditToUser_creditInfo__3m6Ok {
    width: 100%;
  }
  
  .ConfirmCreditToUser_creditInfo__3m6Ok p{
    width: 45%;
  }

  .ConfirmCreditToUser_container_buttons__3PSfX {
    width: 95%;
  }
}
.InformationData_dataCredit__1DvgX {
  top: 80px;
  width: 200px;
  right: 1.5rem;
  position: absolute;
  text-align: center;
  color: var(--COLOR_RED);
}

.InformationData_ImageDataCredit__3c3M5 {
  width: 70%;
}

.InformationData_home__2j_xc {
  width: 50vw;
  margin: auto;
  text-align: center;
}

.InformationData_LogoUno__3opYL {
  right: 2%;
  margin: 1rem 0;
  cursor: pointer;
  position: absolute;
}

.InformationData_textAlert__12vwv {
  margin: -2rem 0 2rem 0;
  font-family: var(--FONT_MEDIUM);
}

.InformationData_textAlert__12vwv > strong {
  color: var(--COLOR_RED);
  font-family: var(--FONT_BOLD);
}

@media (max-width: 1270px) {
  .InformationData_home__2j_xc {
    width: 70vw;
  }
}

@media (max-width: 894px) {
  .InformationData_home__2j_xc {
    width: 80vw;
  }
}

@media (max-width: 800px) {
  .InformationData_home__2j_xc {
    width: 90vw;
  }
}

@media (max-width: 600px) {
  .InformationData_infoCredito__AUaVI {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}
.FormBasic_FormContainer__H4eil{
  margin: 1rem;
}

.FormBasic_formContainer__2BgWq {
  display: flex;
  margin-top: 1.5rem;
  flex-direction: column;
}

.FormBasic_bigInput__dgfKX {
  width: 48%;
  position: relative;
}

.FormBasic_TextForms__1dsMB {
  display: flex;
  align-items: center;
  margin-bottom: 0.3rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.FormBasic_iconInfo__35beF {
  margin-left: 1rem;
}

.FormBasic_SeparateDivs__Ggi30 {
  display: flex;
  min-width: 100%;
  margin-top: 2rem;
  align-items: flex-end;
  margin-bottom: 2.5rem;
  justify-content: space-around;
}

.FormBasic_smallForm__2pX8C {
  width: 30%;
}

.FormBasic_colorText__3hLBL {
  margin: 0;
  margin-right: 0.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_MEDIUM);
}

.FormBasic_container_button__3-ed7 {
  margin-bottom: 2rem;
}

.FormBasic_blockTitle__3iwsl {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}

.FormBasic_iconEye__2mIIh {
  top: 44px;
  right: 20px;
  cursor : pointer;
  position: absolute;
}

.FormBasic_paswordLabel__2h_OB {
  font-size: .8rem;
}

@media (max-width: 800px){
  .FormBasic_SeparateDivs__Ggi30 {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 0rem;
    margin-bottom: 0rem;
  }
  .FormBasic_bigInput__dgfKX {
    width: 100%;
    margin-bottom: 1rem;
    
  }
  .FormBasic_smallForm__2pX8C{
    width: 100%;
    margin-bottom: 1.5rem;
  }
  .FormBasic_TextForms__1dsMB {
    margin-top: 0.9rem;
    display: flex;
    margin-bottom: 0.3rem;
    justify-content: center;
    color: var(--COLOR_TEXT);
    font-family: var(--FONT_MEDIUM);
  }
}

.TheDataPicker_container__1ri6Z {
  min-height: 4.5rem;
  margin-bottom: -1rem;
}
.TheDataPicker_input_date__3tutS { 
  margin: 0;
  width: 100%;
  color: #999; 
  outline: none;
  display: flex;
  min-height: 3rem;
  text-align: center;
  border-radius: 10vh;
  align-items: center;
  background-color: #fff;
  justify-content: center;
  font-family: var(--FONT_LIGHT);
  border: 2px solid rgb(184, 184, 184);
}
.TheDataPicker_input_date__3tutS:hover {
  cursor: pointer;
}
.TheDataPicker_input_date__3tutS .react-date-picker__wrapper {
  border: none;
}
.TheDataPicker_input_date__3tutS .react-date-picker__inputGroup__input:invalid {
  outline: none !important;
  background: none;
}
.TheDataPicker_input_date__3tutS .react-date-picker__inputGroup__day,
.TheDataPicker_input_date__3tutS .react-date-picker__inputGroup__month,
.TheDataPicker_input_date__3tutS .react-date-picker__inputGroup__year {
  outline: none !important;
}
.TheDataPicker_error__2Hi7D {
  font-size: 0.85em;
  text-align: center;
  color: var(--COLOR_RED);
  font-family: var(--FONT_MEDIUM);
}

.TabPanel_tabContainer__T5XFi {
  display: flex;
  margin-top: 2.5rem;
  padding: 10px 0 0 0;
  padding: 0 0 10px 0;
  height: 4.4rem;
  align-content: center;
  justify-content: center;
}

.TabPanel_tabActive__6uhNZ {
  width: 50%;
  color: white;
  cursor: pointer;
  padding: 1.1rem 0;
  text-align: center;
  align-content: center;
  border: 2px solid white;
  font-family: var(--FONT_BOLD);
  background: var(--MEDIUM_BLUE);
}

.TabPanel_tabInactive__2yk5o {
  width: 50%;
  padding: 1.1rem 0;
  cursor: pointer;
  text-align: center;
  background: white;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  border: 2px solid var(--DARK_BLUE);
}
.Empleado_Container__cDRiZ {
  width: 100%;
}

.Empleado_container_input__eJmIg {
  width: 80%;
  margin: auto auto 2rem auto;
}

.Empleado_TextInput__2lL0g {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
  margin-top: 2rem;
}

.Empleado_divInfoContainer__1ABlm {
  text-align: center;
}

@media (max-width: 500px) {
  .Empleado_container_input__eJmIg {
    width: 90%;
  }
  .Empleado_divInfoContainer__1ABlm {
    width: 100%;
    margin-bottom: 0.5rem;
  }
  
}

@media (max-width: 400px) {
  .Empleado_container_input__eJmIg {
    width: 100%;
  }
}

.Independiente_Container__1a4nH{
    text-align: center;
}

.Independiente_Container__1a4nH > * {
    margin-bottom: 1rem;
}

.Searchbar_search__3O5oN{
  width: 100%;
}

.Searchbar_input__3oOQB{
  width: 100%;
  outline: none;
  height: 2.5rem;
  border: 1px solid #CCC;
  border-radius: 5px 5px 0 0;
  padding: 0.5rem 0.5rem 0.5rem 2rem;
}

.Searchbar_searchInputs__2R9Q-{
  position: relative;
  background-color: bisque;
}

.Searchbar_searchIcon__3ZcfJ {
  position: absolute;
  top: 12px;
  left: 10px;
  color: var(--MEDIUM_BLUE)
}

.Searchbar_dataResult__3B_jK{
  width: 100%;
  height: 15vh;
  padding: 0.5rem;
  text-align: left;
  overflow-y: auto;
  margin-bottom: 1rem;
  border-width: 0.1rem;
  border: 1px solid #CCC;
  background-color: white;
}

.Searchbar_dataItem__ifqlY,
.Searchbar_dataItemSelected__1oHSJ{
  cursor: pointer;
  overflow: hidden;
  -webkit-user-select: none;
          user-select: none;
  border-radius: 3px;
  white-space: nowrap;
  padding: .2rem .5rem;
  margin-bottom: .2rem;
  text-overflow: ellipsis;
  font-family: var(--FONT_MEDIUM);
}

.Searchbar_dataItemSelected__1oHSJ {
  color: white;
  background: var(--MEDIUM_BLUE);
}

.Searchbar_info__3JvR2 {
  margin: 0;
  font-size: .9rem;
  font-family: var(--FONT_LIGHT);
}

.Searchbar_infoIcon__2fmV1 {
  color: #c7c763;
  margin-right: .5rem;
}

@media (max-width: 600px) {
  .Searchbar_search__3O5oN{
    width: 100%;
  }
  .Searchbar_input__3oOQB{
    width: 100%;
    height: 2.5rem;
  }
}
.InfoWork_Container__39JfU {
  height: 100vh;
  overflow: scroll;
}

.InfoWork_home__uYfby {
  width: 50vw;
  margin: auto;
}

.InfoWork_logo__2wyH9{
  position: absolute;
  height: 60px;
  right: 30px;
  top: 30px;
}

.InfoWork_container_tab__2a5eU {
  width: 60%;
  margin: auto;
}

@media (max-width: 1200px) {
  .InfoWork_home__uYfby {
    width: 70vw;
  }
}

@media (max-width: 992px) {
  .InfoWork_home__uYfby {
    width: 85vw;
  }

  .InfoWork_container_tab__2a5eU {
    width: 70%;
  }
}

@media (max-width: 768px) {
  .InfoWork_home__uYfby {
    width: 95vw;
  }

  .InfoWork_container_tab__2a5eU {
    width: 80%;
  }

  .InfoWork_logo__2wyH9{
    position: relative;
    right: 0px;
    top: 15px;
  }
  .InfoWork_logoContainer__2lG5b{
    display: flex;
    justify-content: center;
   
  }

}

@media (max-width: 600px) {
  .InfoWork_home__uYfby {
    width: 100vw; 
  }

  .InfoWork_container_tab__2a5eU {
    width: 90%;
  }
}

.InfoWorkMoneyPerson_SmallDivsContainer__2s_s2 {
  display: flex;
  justify-content: space-between;
}

.InfoWorkMoneyPerson_SmallDivs__3W_hv {
  width: 48%;
  margin-bottom: 2rem;
}

.InfoWorkMoneyPerson_TextInput__3sChM {
  font-size: 0.8rem;
  margin-bottom: 0.3rem;
}

@media (max-width: 600px){
  .InfoWorkMoneyPerson_SmallDivs__3W_hv {
    width: 49%;
  }
}

@media (max-width: 400px){
  .InfoWorkMoneyPerson_SmallDivsContainer__2s_s2 {
    display: block;
  }

  .InfoWorkMoneyPerson_SmallDivs__3W_hv {
    width: 100%;
  }
}

.InfoValidate_Container__1act-{
  width: 45vw;
  margin: auto;
  min-height: 100vh;
  text-align: center;
}

@media (max-width: 1445px) {
  .InfoValidate_Container__1act-{
    width: 55vw;
  }
}

@media (max-width: 1200px) {
  .InfoValidate_Container__1act-{
    width: 65vw;
  }
}

@media (max-width: 980px) {
  .InfoValidate_Container__1act-{
    width: 75vw;
  }
}

@media (max-width: 880px) {
  .InfoValidate_Container__1act-{
    width: 85vw;
  }
}

@media (max-width: 600px) {
  .InfoValidate_Container__1act-{
    width: 90vw;
  }
}

.InfoValidate_Container__yeW2A {
  text-align: center;
}

.InfoValidate_TextColor__1QkAG {
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.InfoValidate_containerImg__pAABs {
  padding-top: 1rem;
  
}

.InfoValidate_LogoUno__1AVPS{
  height: 60px;
  margin-top: 5px;
}

.InfoValidate_Logo__DAOEz {
  width: 12rem;
  margin-top: 3rem;
}

.InfoValidate_textInfo__10cU1 {
  width: 75%;
  margin-top: 0.8rem;
  margin: 1.5rem auto 2.5rem;
  line-height: 1.3rem;
  font-family: var(--FONT_LIGHT);
 
}

.InfoValidate_titleInfo__1SRsT {
  margin: 0;
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: -2.5rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}
.FormLinksTyC_input__27ZGg {
  margin-right: 1rem;
}

.FormLinksTyC_Container__3FOp4 > label {
  margin: .6rem 0;
  -webkit-user-select: none;
          user-select: none;
  align-items: center;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.FormLinksTyC_textColorTerms__1nrfn{
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
  cursor: pointer;
  text-decoration: none;
}

.FormLinksTyC_iconChecked__hGHzD,
.FormLinksTyC_icon__3DwJ9 {
  font-size: 1.2rem;
  margin-right: 1rem;
}

.FormLinksTyC_iconChecked__hGHzD {
  color: var(--MEDIUM_BLUE);
}

.FormLinksTyC_icon__3DwJ9 {
  color: #989696;
}

.FormLinksTyC_checkboxDisplay__3mquf{
  display: flex;
  justify-content: center;
}
.FormLinksTyC_checkboxDisplay__3mquf > *{
  margin-bottom: 0.5rem;
}

@media (max-width:800px){
  .FormLinksTyC_Container__3FOp4 > label {
    display: flex;
    text-align: start;
    font-size: 1rem;
    align-items: flex-start;
    margin-left: 8%;
  }
  .FormLinksTyC_textColorTerms__1nrfn{
    color: var(--MEDIUM_BLUE);
    font-family: var(--FONT_BOLD);
    text-decoration: none;
  }
}
.ModalValidate_alignModal__30QhB {
  display: flex;
}

.ModalValidate_modalContainer__19Nhu {
  margin: auto;
  padding: 2rem 1rem;
  text-align: center;
}

.ModalValidate_modalIMG__2ODlz {
  width: 30%;
}

.ModalValidate_colorLightBlue__25vja {
  margin-top: 1rem;
  font-size: 1.3rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.ModalValidate_textInfo__25eDb {
  margin: 0;
  color: #999;
  font-family: var(--FONT_BOLD);
}

.TheText_ContainerLong__36Mgb {
  min-width: 50%;
  max-width: 50%;
  margin: auto 0 auto;
}

.TheText_NameText__3I2nj {
  margin-bottom: 0;
  font-size: 0.8rem;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_LIGHT);
}

.TheText_Data__RqohV {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}

.TheText_longData__BinLM {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
  width: 100%;
}

@media (max-width: 356px) {
  .TheText_Container__2KweK,
  .TheText_ContainerLong__36Mgb {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
.InfoBasicWork_Container__EYM1k {
  margin: auto;
  max-width: 85%;
}

.InfoBasicWork_containerInfo__Mra97 {
  margin: 2.5rem 0;
}

.InfoBasicWork_Divs__2LuUu {
  display: flex;
  margin-bottom: 1rem;
  justify-content: space-evenly;
}

.InfoBasicWork_title__3p3aO {
  margin: 0;
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.InfoBasicWork_DivsDifference__3HlwE {
  display: flex;
  margin-bottom: 1.5rem;
  justify-content: space-evenly;
}

.InfoBasicWork_containerButton__17evN {
  width: 50%;
  margin: auto;

}

@media (max-width: 500px) {
  .InfoBasicWork_Container__EYM1k {
    max-width: 100%;
  }
  .InfoBasicWork_Divs__2LuUu {
    margin-left: 0;
    max-width: 100%;
    flex-wrap: wrap;
  }
  .InfoBasicWork_Divs__2LuUu>*{
    width: 10rem;
  }
  .InfoBasicWork_DivsDifference__3HlwE {
    display: flex;
    flex-direction: column;
    margin-top: -4.7rem;
  }
  #InfoBasicWork_DivResponsive1__c23kq{
    margin: 1rem 13rem 0rem 2rem;
  }
  #InfoBasicWork_DivResponsive2__2dqah{
    margin: 0rem -2rem 1.5rem 8rem;
  }

  .InfoBasicWork_containerButton__17evN {
    width: 100%;
  }
}

@media (max-width: 347px) {
  .InfoBasicWork_Divs__2LuUu {
    margin-bottom: 0rem;
  }
  #InfoBasicWork_DivResponsive1__c23kq{
    width: 100%;
    margin: 0rem;
  }
  #InfoBasicWork_DivResponsive2__2dqah{
    margin: 4.7rem 0rem 0rem 0rem;
  }
}


.ModalEditInfoWork_modal_body__1Dk4q {
  text-align: center;
  padding: 1rem 2rem 2rem 2rem;
}

.ModalEditInfoWork_close__2OXE0 {
  top: 10px;
  right: 15px;
  font-size: 1.3rem;
  position: absolute;
  color: VAR(--COLOR_TEXT)
}

.ModalEditInfoWork_modal_title__3Kjve {
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
  margin-top: 0.5rem;
  font-size: 1.5rem;
}

.ModalEditInfoWork_contanerInputsGrouped__1RdTU {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

.ModalEditInfoWork_containerInput__2W61B {
  width: 48%;
  text-align: left;
}

.ModalEditInfoWork_containerInputSmall__1f0S7 {
  width: 31%;
  text-align: left;
}

.ModalEditInfoWork_label__1X9PJ {
  margin: .3rem;
  font-size: .8rem;
  color: VAR(--COLOR_TEXT);
  font-family: VAR(--FONT_LIGHT);
}

.ModalEditInfoWork_label__1X9PJ > span {
  color: var(--MEDIUM_BLUE);
  font-family: VAR(--FONT_BOLD);
}

.ModalEditInfoWork_containerButton__3It_G {
  width: 50%;
  margin: auto;
}

.ModalEditInfoWork_label_error__1V8Rf {
  font-size: 0.8em;  
  margin-top: -1rem;
  margin-bottom: 10px;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}


@media (max-width: 992px){
  .ModalEditInfoWork_contanerInputsGrouped__1RdTU {
    justify-content: space-around;
  }

  .ModalEditInfoWork_containerInputSmall__1f0S7 {
    width: 48%;
    text-align: left;
  }
}

@media (max-width: 500px){
  .ModalEditInfoWork_modal_body__1Dk4q {
    padding: 2rem 1rem 1rem 1rem;
  }

  .ModalEditInfoWork_containerInput__2W61B,
  .ModalEditInfoWork_containerButton__3It_G,
  .ModalEditInfoWork_containerInputSmall__1f0S7 {
    width: 90%;
  }
}

@media (max-width: 400px){
  .ModalEditInfoWork_containerInput__2W61B,
  .ModalEditInfoWork_containerButton__3It_G,
  .ModalEditInfoWork_containerInputSmall__1f0S7 {
    width: 100%;
  }
}
