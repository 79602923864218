.container {
  z-index: 1;
  right: 50px;
  width: 65px;
  bottom: 50px;
  height: 65px;
  display: grid;
  color: white;
  cursor: pointer;
  position: fixed;
  user-select: none;
  font-size: 2.8rem;
  border-radius: 50%;
  align-items: center;
  align-content:center;
  background: #25D366;
  justify-content:center;
}

.container:hover {
  color: white;
}

@media (max-width: 600px) {
  .container {
    width: 55px;
    right: 15px;
    bottom: 15px;
    height: 55px;
    font-size: 2.3rem;
  }
}