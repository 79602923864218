.principal_container {
  height: 100%;
  outline: none;
  display: flex;
  font-size: 16px;
  position: relative;
  align-items: center;
  justify-content: center;
}
.content_modal {
  width: 65vw;
  padding: 2rem;
  display: flex;
  min-width: 898px;
  border-radius: 10px;
  align-items: center;
  flex-direction: row;
  background-color: white;
}
.arrow_icon {
  top: 20px;
  left: 20px;
  display: none;
  font-size: 1.5em;
  position: absolute;
  color: var(--MEDIUM_BLUE);
}
.section_info {
  width: 50%;
  display: flex;
  padding-right: 40px;
  align-items: center;
  flex-direction: column;
}
.form_login {
  width: 50%;
  padding-left: 20px;
  position: relative;
}
.login_title {
  font-size: 1.2em;
  text-align: center;
  margin-bottom: 10px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.subtitle {
  color: #333;
  font-size: 1em;
  text-align: center;
  margin-bottom: 30px;
  font-family: var(--FONT_MEDIUM);
}
.label {
  font-size: 1em;
  margin-top: 1rem;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.form {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.input {
  width: 85%;
  text-align: center;
}
.forgot_password {
  color: #333;
  font-size: 0.9em;
  margin-top: 30px;
  text-align: center;
  margin-bottom: 7px;
  font-family: var(--FONT_MEDIUM);
}
.recover_password {
  font-size: 1em;
  text-align: center;
  margin-bottom: 7px;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}
.recover_password:hover {
  cursor: pointer;
}
.on_back {
  color: #333;
  font-size: 1em;
  margin-top: 20px;
  cursor: pointer;
  margin-bottom: 0;
  text-align: center;
  font-family: var(--FONT_BOLD);
}
.on_back:hover {
  cursor: pointer;
}
.label_error {
  font-size: 0.8em;
  color: VAR(--COLOR_RED);
  font-family: VAR(--FONT_MEDIUM);
}

.close_icon {
  top: -15px;
  right: -15px;
  color: #999;
  cursor: pointer;
  font-size: 1.5em;
  position: absolute;
}

@media (max-width: 898px) {
  .content_modal {
    min-width: 768px;
  }
}
@media (max-width: 768px) {
  .principal_container {
    font-size: 15px;
  }
  .arrow_icon {
    display: flex;
  }
  .content_modal {
    width: 100vw;
    height: 100vh;
    border-radius: 0;
    flex-direction: column;
    justify-content: space-between;
  }
  .section_info {
    padding: 0;
    height: 30%;
    justify-content: center;
  }
  .form_login {
    padding: 0;
    height: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .on_back {
    height: 20%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .close_icon {
    display: none;
  }
}
@media (max-width: 374px) {
  .form_login {
    width: 290px;
  }
}
