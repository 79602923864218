.container_title {
  display: flex;
  font-size: 16px;
  cursor: pointer;
  align-items: center;  
  padding-bottom: 20px;
}
.title {
  font-size: 1.2em;
  color: #015c83;
  margin-bottom: 0;
  font-family: var(--FONT_BOLD);
}
.icon {
  font-size: 1.7em;
  color: #015c83;
  margin-right: 15px;
  font-family: var(--FONT_LIGHT);
}

@media (max-width: 768px) {
  .container_title { justify-content: center; }
}