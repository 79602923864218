.container {
  top: 60px;
  z-index: 1;
  right: 20px;
  width: 250px;
  padding: 1rem;
  position: absolute;
  background: white;
  border-radius: 7px;
  border: 1.5px solid var(--COLOR_DISABLED);
}

.item {
  display: flex;
  cursor: pointer;
  user-select: none;
  align-items: center;
  justify-content: space-between;
}

.text {
  margin: 0;
  font-family: var(--FONT_MEDIUM);
}

.icon {
  font-size: 1.3rem;
}