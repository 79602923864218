.Container {
  text-align: center;
}

.TextColor {
  font-size: 1.2rem;
  color: var(--MEDIUM_BLUE);
  font-family: var(--FONT_BOLD);
}

.containerImg {
  padding-top: 1rem;
  
}

.LogoUno{
  height: 60px;
  margin-top: 5px;
}

.Logo {
  width: 12rem;
  margin-top: 3rem;
}

.textInfo {
  width: 75%;
  margin-top: 0.8rem;
  margin: 1.5rem auto 2.5rem;
  line-height: 1.3rem;
  font-family: var(--FONT_LIGHT);
 
}

.titleInfo {
  margin: 0;
  font-size: 1.3rem;
  margin-top: 1rem;
  margin-bottom: -2.5rem;
  color: var(--LIGHT_BLUE);
  font-family: var(--FONT_BOLD);
}