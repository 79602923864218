.alignModal {
  height: 100%;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.modalContainer {
  width: 40%;
  padding: 2rem;
  display: flex;
  border-radius: 10px;
  align-items: center;
  flex-direction: column;
  background-color: white;
}
.modalIMG {
  width: 25%;
  min-width: 110px;
}
.colorGreen {
  margin-top: 2rem;
  font-size: 1.5em;
  color: var(--COLOR_SUCCESS);
  font-family: var(--FONT_BOLD);
}
.text {
  color: var(--COLOR_TEXT);
  font-family: var(--FONT_MEDIUM);
}
.container_button {
  width: 60%;
}

@media (max-width: 1111px) {
  .modalContainer {
    width: 60%;
  }
}

@media (max-width: 900px) {
  .modalContainer {
    width: 70%;
  }
}

@media (max-width: 478px) {
  .modalContainer {
    width: 80%;
    padding: 1rem;
  }

  .container_button {
    width: 95%;
  }
}

@media (max-width: 400px) {
  .modalContainer {
    width: 95%;
  }

  .container_button {
    width: 100%;
  }
}